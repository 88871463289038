import Vue from "vue"
// base library
import "@/core/lib/antDesignVue"
import "@/core/lib/globalComponents"
import "@/directive/dragModal.js"
import { axios, resMsg } from "@/utils/http"
import plugins from "@/plugins"
import config from "@/config/defaultSettings"
import Storage from "vue-ls"
import animated from "animate.css"
import MultiTab from "@/components/frame/MultiTab"
import Viser from "viser-vue"
import AModal from "@/components/resetAntdv/AModal.vue"

Vue.use(plugins)
Vue.use(Storage, config.storageOptions)
Vue.use(animated)
Vue.use(MultiTab)
Vue.use(Viser)
Vue.component(AModal.name, AModal)

Vue.prototype.$axios = axios;
Vue.prototype.$resMsg = resMsg;
