import constRoutes from "@/router/routes"
import i18n from "@/locales"

const permission = {
  state: {
    routes: constRoutes,
    addRoutes: [], // 动态路由
    curRoutePer: [], // 当前页面路由按钮权限
    curRoutePerCode: [], // 当前页面路由按钮权限Code
    sideMenus: [], // 临时侧栏菜单
    activeTopNav: [], // 顶部菜单当前活跃项
    topMenuId: "", // 当前 topMenuId
    tabPages: [], // tab当前打开组件
    isClickSideNav: false // 是否点击侧边栏一级菜单，用于二级菜单根据此凭证默认选中第一个应用
  },
  mutations: {
    SET_TABPAGES: (state, data) => {
      state.tabPages = data;
    },
    SET_ROUTES: (state, data) => {
      state.addRoutes = data;
      state.routes = constRoutes.concat(data);
    },
    SET_CURROUTE_PER: (state, data) => {
      state.curRoutePer = data;
      state.curRoutePerCode = []
      for (const i in data) {
        state.curRoutePerCode.push(data[i].permission)
      }
    },
    SET_SIDEMENUS: (state, data) => {
      let menus = [];
      // console.log("SET_SIDEMENUS,,,", state, data)
      if (data.type === 1) {
        // 我的工作台菜单
        const myWorkPlace = [
          {
            path: "/home",
            name: "myHome",
            component: "/Home.vue",
            meta: {
              topMenuId: "myMenu",
              title: i18n.t('frame.menu.homePage')
            }
          }
          // {
          //   path: "/apps",
          //   name: "apps",
          //   component: "/system/apps/Apps.vue",
          //   meta: {
          //     topMenuId: "myMenu",
          //     title: i18n.t('frame.app.normal')
          //   }
          // },
          // {
          //   path: "/myMenus",
          //   name: "myMenus",
          //   component: "/base/menu/MyMenus.vue",
          //   meta: {
          //     topMenuId: "myMenu",
          //     title: i18n.t('frame.menus.normal')
          //   }
          // },
          // {
          //   path: "/messages",
          //   name: "messageList",
          //   component: "/base/message/============List.vue",
          //   meta: {
          //     topMenuId: "myMenu",
          //     title: i18n.t('frame.my.message')
          //   }
          // }
        ];
        menus = myWorkPlace;
      } else if (data.type === 2) {
        menus = data.menus;
      } else {
        menus = data.menus;
      }
      state.sideMenus = menus === undefined ? [] : menus; // 处理 undefined 报错
    },
    SET_ACTIVETOPNAV: (state, id) => {
      id = id === undefined ? "" : id;
      state.activeTopNav = [id];
    },
    SET_TOPMENUID: (state, id) => {
      state.topMenuId = id;
    },
    SET_ISCLICKSIDENAV: (state, is = false) => {
      state.isClickSideNav = is;
    }
  },
  actions: {
    // 动态更新主界面路由，需要缓存
    updateRoutesAction ({ commit }, routes) {
      return new Promise(resolve => {
        commit("SET_ROUTES", routes)
        resolve()
      })
    }
  }
}

export default permission
