/*!
 * @file api/menu.js
 * @desc 菜单管理
 */

export default ({ request, baseURL }) => ({

  // 查询菜单树
  queryMenuTree (url) {
    url = url || `${baseURL}/system/menu/treeselect`;
    return request({
      url: url,
      method: "get"
    })
  },

  // 获取当前登录人的菜单和权限
  queryLoginUserMenu (subsystemId) {
    let config = {
      url: `${baseURL}/system/menu/getRouters`,
      method: "get"
    }
    if (subsystemId) {
      // 带指定 token 查询登录用户信息
      config.headers = { "SUBSYSTEM-ID": subsystemId }
    }
    return request(config)
  },

  // 常用应用列表
  queryCommonAppList () {
    return request({
      url: `${baseURL}/admin/admin/AppCenter/common`,
      method: "get"
    })
  },

  // 应用中心列表
  queryAppList () {
    return request({
      url: `${baseURL}/admin/admin/AppCenter/list`,
      method: "get"
    })
  },

  // 根据参数获取外链
  getOutLink (data) {
    return request({
      url: `${baseURL}/admin/ThirdPartyConfig/replaceCode`,
      method: "post",
      data
    })
  },

  // 获取收藏菜单
  getCollectMenu () {
    return request({
      url: `${baseURL}/admin/admin/AppCenter/collectMenu`,
      method: "get"
    })
  },

  // 收藏菜单
  adCollectMenu (data) {
    return request({
      url: `${baseURL}/admin/admin/AppCenter/menu`,
      method: 'put',
      data
    })
  },

  // 应用池概念 - 子系统管理 - 查询子系统列表
  getMenuSubsystem () {
    return request({
      url: `${baseURL}/admin/MenuSubsystem/list`,
      method: 'get'
    })
  }

})
