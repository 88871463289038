<template>
  <a-modal
    title="选择候选角色"
    :dialog-style="{ top: '20px' }"
    :visible="visible"
    @ok="save"
    @cancel="$emit('close')"
    width="1000px"
    class="user-picker"
  >
  <div>
    <!-- 查询表单标签( <c-query-param></c-query-param> ) -- start -->
      <a-form @keyup.enter.native="submitQuery" layout="inline" class="search-box">
        <div style="display: flex;">
          <a-form-item label="角色名称">
            <a-input
              placeholder="请输入角色名称"
              v-model.trim="queryParam.roleName"
            ></a-input>
          </a-form-item>
          <div class="for-btn for-btn-config">
            <a-button
              type="primary"
              @click="submitQuery"
              icon="search"
              :loading="queryLoading"
              >查询</a-button
            >
            <a-button
              @click="resetQuery"
              icon="reload"
              :loading="resetQueryLoading"
              >重置</a-button
            >
          </div>
        </div>
      </a-form>
    </div>
    <!-- 查询表单标签( <c-query-param></c-query-param> ) -- end -->
    <!-- 查询结果标签( <c-query-result></c-query-result> ) -- start -->
    <c-query-result ref="queryResult">
      <!-- 设置一屏适配表格列表内联滚动高度：<template v-slot="{ table }"> 且 :scroll="{ x: 1000, y: table.tableBodyHeight }" -->
      <!-- useMinHeight：true（默认） || false 属性 可以设置表格最小高度达到占满一屏 -->
        <!-- 列表记录 -->
          <a-table
            ref="table"
            class="records-table"
            :class="recordsTableClass"
            rowKey="id"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="false"
            :loading="loading"
            bordered
            :rowSelection="{
              columnWidth: 38,
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            @change="handleTableChange"
          >
            <template slot="sequenceNumber" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
            <template slot="sort" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
          </a-table>

      <div class="query-result-footer">
        <div class="left" v-if="selectedRowKeys.length">
          <a-button @click="onClearSelected" size="small">清空</a-button>
          <span
            >已选择<em class="num">{{ selectedRowKeys.length }}</em
            >项</span
          >
        </div>
        <div class="right">
          <span
            >共<em class="num">{{ pagination.total }}</em
            >条记录</span
          >
          <a-pagination
            :show-quick-jumper="true"
            :pageSizeOptions="pagination.pageSizeOptions"
            :current="pagination.current"
            :total="pagination.total"
            showSizeChanger
            :pageSize="pagination.pageSize"
            v-model="pagination.current"
            @change="currentChange"
            @showSizeChange="pageSizeChange"
          />
        </div>
      </div>
    </c-query-result>
    <!-- 查询结果标签( <c-query-result></c-query-result> ) -- end -->
  </a-modal>
</template>
<script>
// @ is an alias to /src
import { queryList } from "@/mixins/queryList"
import config from "@/config/defaultSettings"
export default {
  name: "RolePicker",
  mixins: [queryList],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      queryParam: {
        roleName: ""
      },
      isShowQueryForm: true,
      recordsTableClass: config.recordsTable.defaultSizeClass,
      currentcolums: [],
      // 表头
      columns: [
        {
          title: "角色编号",
          align: "center",
          dataIndex: "sequenceNumber",
          scopedSlots: { customRender: "sequenceNumber" },
          width: 120
        },
        {
          ellipsis: true,
          title: "角色名称",
          align: "center",
          dataIndex: "roleName",
          width: 140
        },
        // {
        //   ellipsis: true,
        //   title: "权限字符",
        //   align: "center",
        //   dataIndex: "roleName",
        //   width: 140
        // },
        {
          title: "显示顺序",
          align: "center",
          scopedSlots: { customRender: "sort" },
          width: 120
        },
        {
          ellipsis: true,
          title: "创建时间",
          align: "left",
          dataIndex: "createTime"
        }
      ],

      url: {
        list: "/ofa-admin/admin/ofaRole/role/list"
      }
    }
  },
  methods: {
    save () {
      this.$emit("save", { selectedRowKeys: this.selectedRowKeys, selectionRows: this.selectionRows });
      this.$emit('close');
    }
  }
}
</script>
<style lang="scss" scoped>
  .for-btn {
    display: inline-block;
    .ant-btn {
      margin-left: 8px;
    }
  }
  .search-box {
    ::v-deep {
      .ant-form-item-label,
      .ant-form-item-control {
        line-height: 32px !important;
        display: inline-block;
      }
    }
  }
  .query-result-content {
    margin-top: 10px;
  }
</style>
