<template>
  <div ref="scrollView" :style="getScrollViewStyle(this.svType)">
    <slot></slot>
  </div>
</template>

<script>
import { getPoint, findAncestor, getFatherElePoint } from "@/utils";
export default {
  props: {
    // 一屏幕视图 滚动类型
    svType: {
      type: Number,
      default: 0 // 0: 新建、编辑、详情，1: 页面空白主要内容区, 2: 左树右列表的左树滚动区域
    },
    svHeight: { // 传入高度
      type: [String, Number]
    }
  },
  data () {
    return {
      scrollViewHeight: 0 // 滚动视图高度
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => { this.initBodyHeight(); }, 200)
    })
  },
  methods: {
    initBodyHeight () {
      let p = getPoint(this.$refs["scrollView"]);
      let scrollViewOffsetTop = (p && p.t) || 0;
      let clientHeight = document.body.clientHeight
      let isExistModal = findAncestor(this.$refs["scrollView"], "ant-modal")
      if (isExistModal) { // 如果祖先元素存在弹框
        clientHeight = isExistModal.clientHeight
        let p2 = getFatherElePoint(this.$refs["scrollView"], isExistModal)
        scrollViewOffsetTop = (p2 && p2.t) || 0
      }
      // const gfOffsetHeight = document.getElementById("globalFooter").offsetHeight || 30; // globalFooter 是页面底部高度
      this.scrollViewHeight = clientHeight - scrollViewOffsetTop - 8;
    },
    getScrollViewStyle (svType) {
      let height = 0;
      if (this.svHeight) {
        height = this.svHeight;
      } else {
        if (svType === 0) {
          // 0: 新建、编辑、详情
          height = this.scrollViewHeight - 24;
        } else if (svType === 1) {
          // 1: 页面空白主要内容区
          height = this.scrollViewHeight - 24;
        } else if (svType === 2) {
          // 2: 左树右列表的左树滚动区域
          height = this.scrollViewHeight - 27;
        }
      }
      return {
        "height": `${height}px`,
        "overflow-y": "auto"
      }
    }
  }
}
</script>
