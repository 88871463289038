import {
  getFiltersFromColumns,
  getColumnKey,
  flatFilter
} from './utils'

export default {
  methods: {
    getDefaultFilters (columns) {
      const definedFilters = getFiltersFromColumns({ columns: this.columns }, columns);

      const defaultFilteredValueColumns = flatFilter(
        columns || [],
        column => typeof column.defaultFilteredValue !== 'undefined'
      );

      const defaultFilters = defaultFilteredValueColumns.reduce((soFar, col) => {
        const colKey = getColumnKey(col);
        soFar[colKey] = col.defaultFilteredValue;
        return soFar;
      }, {});

      return { ...defaultFilters, ...definedFilters };
    },

    getDefaultSortOrder (columns) {
      const definedSortState = this.getSortStateFromColumns(columns);

      const defaultSortedColumn = flatFilter(columns || [], column => {
        return column.defaultSortOrder != null;
      })[0];

      if (defaultSortedColumn && !definedSortState.sortColumn) {
        return {
          sSortColumn: defaultSortedColumn,
          sSortOrder: defaultSortedColumn.defaultSortOrder
        };
      }

      return definedSortState;
    },
    getSortStateFromColumns (columns) {
      // return first column which sortOrder is not falsy
      const sortedColumn = this.getSortOrderColumns(columns).filter(col => col.sortOrder)[0];

      if (sortedColumn) {
        return {
          sSortColumn: sortedColumn,
          sSortOrder: sortedColumn.sortOrder
        };
      }

      return {
        sSortColumn: null,
        sSortOrder: null
      };
    },
    getSortOrderColumns (columns) {
      return flatFilter(columns || this.columns || [], column => 'sortOrder' in column);
    },
    getSorterFn (state) {
      const { sSortOrder: sortOrder, sSortColumn: sortColumn } = state || {};
      if (!sortOrder || !sortColumn || typeof sortColumn.sorter !== 'function') {
        return;
      }

      return (a, b) => {
        const result = sortColumn.sorter(a, b, sortOrder);
        if (result !== 0) {
          return sortOrder === 'descend' ? -result : result;
        }
        return 0;
      };
    },
    recursiveSort (data, sorterFn) {
      const { childrenColumnName = 'children' } = this;
      return data.sort(sorterFn).map(item =>
        item[childrenColumnName]
          ? {
            ...item,
            [childrenColumnName]: this.recursiveSort([...item[childrenColumnName]], sorterFn)
          }
          : item
      );
    }
  }
}
