<template>
  <div ref="recordsTable" class="query-result-content" :style="useMinHeight && getStyle()">
    <slot v-bind:table="{ tableBodyHeight: tableBodyHeight }"></slot>
  </div>
</template>

<script>
import { getPoint, findAncestor, getFatherElePoint } from "@/utils";
import { mapState } from "vuex"
export default {
  props: {
    useMinHeight: {
      type: Boolean,
      default: true
    },
    isShowQueryForm: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isFirst: true, // 是否是第一次初始化
      queryFormHeight: 0, // 搜索条件高度
      tableBodyHeight: 0,
      minHeight: 0 // 最小高度
    };
  },
  computed: {
    ...mapState({
      isFullScreen: state => state.app.isFullScreen
    })
  },
  watch: {
    isFullScreen (b) {
      this.initHeight();
    },
    isShowQueryForm: {
      handler (v) {
        if (!this.isFirst) {
          this.initHeight()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      let queryView = document.getElementsByClassName('query-param')[0];
      let rect = queryView?.getBoundingClientRect()
      this.queryFormHeight = rect?.height || 0;
      let i = 1;
      while (i < 3) {
        setTimeout(() => {
          this.initHeight();
        }, 500 * i);
        i++;
      }
    });
  },
  methods: {
    initHeight () {
      let modal = findAncestor(this.$refs["recordsTable"], "ant-modal")
      if (modal) { // 判断外层元素是否有弹框
        const clientHeight = modal.clientHeight
        const recordsTable = getFatherElePoint(this.$refs["recordsTable"], modal);
        const tableOffsetTop = (recordsTable && recordsTable.t) || 0;
        this.minHeight = clientHeight - tableOffsetTop - 80;
      } else {
        const clientHeight = document.body.clientHeight;
        const recordsTable = getPoint(this.$refs["recordsTable"]);
        const tableOffsetTop = (recordsTable && recordsTable.t) || 0;
        this.minHeight = clientHeight - tableOffsetTop;
        this.tableBodyHeight = this.minHeight - 118;

        if (this.tableBodyHeight < 322) {
          // 防止小屏幕只显示几行
          this.tableBodyHeight = 322
        }

        // if (!this.isFirst) {
        //   if (!this.isShowQueryForm) { // 隐藏搜索条件时，高度需要增加才能铺满
        //     this.tableBodyHeight += this.queryFormHeight;
        //   } else {
        //     this.tableBodyHeight -= this.queryFormHeight;
        //   }
        // }
      }
      this.isFirst = false;
    },
    getStyle () {
      return {
        minHeight: `${this.minHeight - 80}px`
      };
    }
  }
};
</script>

<style scoped lang="scss">
.query-result-content {
  ::v-deep {
    .ant-table-row-expand-icon {
      background-color: $color-primary;
      color: #fff;
      border: none;
    }

    .records-table-small {
      .ant-table-thead {
        >tr {
          >th {
            padding: 2px 8px 2px !important;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            padding: 2px 8px 2px !important;
          }
        }
      }
    }

    .records-table-middle {

      .ant-table-selection-column,
      .ant-table-selection {
        .ant-checkbox-wrapper {
          margin-bottom: 0 !important;
        }
      }

      .ant-table-thead {
        >tr {
          >th {
            padding: 5px 6px !important;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            padding: 5px 6px !important;
          }
        }

        .row-operation-btn {
          line-height: 0;

          .ant-btn-link {
            height: 20px;
          }

          .more {
            height: 18px;
          }
        }
      }
    }

    .records-table-default {
      .ant-table-thead {
        >tr {
          >th {
            padding: 10px 8px 9px !important;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            padding: 8px 8px 8px !important;
          }
        }
      }
    }

    .records-table {
      .ant-table-thead {
        >tr {
          >th {
            // padding: 10px 8px 9px !important;
            background-color: #f7f8fc;
            // border-bottom: none;
          }
        }
      }

      .ant-table-tbody {
        background-color: #fff;

        .ant-table-row-expand-icon {
          background-color: $color-primary;
        }

        tr {
          td {

            // padding: 8px 8px 8px !important;
            // border-bottom: 0;
            .ant-divider-vertical {
              margin: 0;
            }
          }

          &:nth-child(even) {
            // background-color: #f7f8fc;
          }
        }
      }

      // 自定义添加Table空值字符
      .ant-table-cell:empty:after,
      .ant-table-cell span:empty:after {
        content: '-';
      }

      .ant-table-empty {
        .ant-table-placeholder {
          border: none;
        }
      }
    }
  }
}</style>
