import Vue from "vue"
import store from "@/store/"
import {
  SIDEBAR_TYPE,
  ACCESS_TOKEN,
  LOGIN_USER_INFO,
  APP_LANGUAGE
} from "@/store/mutation-types"

export default function Initializer () {
  store.commit("SET_SIDEBAR_TYPE", Vue.ls.get(SIDEBAR_TYPE, false))
  // token
  store.commit("SET_TOKEN", Vue.ls.get(ACCESS_TOKEN))
  // 用户信息
  store.commit("SET_INFO", Vue.ls.get(LOGIN_USER_INFO))

  store.dispatch('setLang', Vue.ls.get(APP_LANGUAGE, 'zh-CN'))
}
