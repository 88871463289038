import sysconfig from "@/config/defaultSettings"
import { SUBSYSTEM_ID } from "@/store/mutation-types"
import { isBlank } from "@/utils";
const defaultLogo = require('@/assets/img/logo_platform.png');
/**
 * 获取客户端设备信息
 * @param  {Object} state Vuex状态对象
 * @return {Object}       客户端会话信息
 */
export const device = state => state.app.device

/**
 * 获取头像
 * @param  {Object} state Vuex状态对象
 * @return {Object}
 */
export const avatar = state => "" // { state.user.avatar = Vue.ls.get(USER_INFO).avatar; return state.user.avatar }

/**
 * 获取登录用户信息
 * @param  {Object} state Vuex状态对象
 * @return {Object}
 */
export const userInfo = state => state.user.info;

/**
 * 获取动态添加的可访问路由表
 * @param  {Object} state Vuex状态对象
 * @return {Object}
 */
export const addRoutes = state => state.permission.addRoutes

/**
 * 获取侧栏菜单
 * @param  {Object} state Vuex状态对象
 * @return {Object}
 */
export const menuPermission = state => state.user.menuPermission

/**
 * 获取登录token
 * @param  {Object} state Vuex状态对象
 * @return {String} token
 */
export const token = state => state.user.token

/**
 * 获取登录用户未读消息数
 * @param  {Object} state Vuex状态对象
 * @return {Number} 未读消息数
 */
export const messNum = state => state.app.messNum;

// 国际化 当前语言
export const lang = state => state.app.lang;

// 应用池感念 当前子系统
export const subsystem = state => {
  const subsystemId = process.env.VUE_APP_SUBSYSTEM_ID || sessionStorage.getItem(sysconfig.storageOptions.namespace + SUBSYSTEM_ID);
  return state.app.subsystems.find(item => item.id === subsystemId);
}

// 系统logo
export const logo = state => {
  let logo = "";
  let icon = state.user.curOrg.avatar;
  if (icon) {
    logo = icon;
  }
  if (isBlank(logo)) {
    logo = defaultLogo
  }
  return logo;
}
