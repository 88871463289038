<template>
  <div ref="queryList" class="query-list" :class="{ 'query-list-has-sider': layoutType }">
    <template v-if="layoutType">
      <div class="query-list-sider" :class="{ 'query-list-sider-stretch': isStretch }"
        :style="{ width: siderWidth + '%' }">
        <slot name="query-sider"></slot>
        <template v-if="showSilderSwitch">
          <span class="query-sider-stretch" @mouseenter="onMouseEnter" @mouseleave="onMouseleave"
            @click="onClickStretch">
            <img class="query-sider-stretch-bg" src="@/assets/img/icon/icon_stretch.png" />
            <img v-if="!isHover" class="query-sider-stretch-arrow" src="@/assets/img/icon/icon_put.png"
              :style="{ transform: `rotateY(${rotationAngle}deg) translateY(-50%)` }" />
            <img v-else class="query-sider-stretch-arrow" src="@/assets/img/icon/icon_put_hover.png"
              :style="{ transform: `rotateY(${rotationAngle}deg) translateY(-50%)` }" />
          </span>
          <div v-if="isStretch" class="stretch-cover"></div>
        </template>
      </div>
      <div class="query-list-main" :style="{ width: (98.8 - siderWidth) + '%' }">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    layoutType: {
      type: Number,
      default: 0 // 0:常规列表、 1:左树右列表
    },
    showSilderSwitch: {
      type: Boolean,
      default: true //  true,显示折叠按钮，false,不显示
    }
  },
  data() {
    return {
      isHover: false,
      siderWidth: 20,
      isStretch: false,
      rotationAngle: 0
    }
  },
  methods: {
    onMouseEnter() {
      this.isHover = true;
    },
    onMouseleave() {
      this.isHover = false;
    },
    onClickStretch() {
      this.siderWidth = this.siderWidth === 20 ? 1 : 20;
      this.isStretch = !this.isStretch;
      this.rotationAngle = this.isStretch ? 180 : 0;
    }
  }
}
</script>

<style scoped lang="scss">
.query-list {
  border-radius: 8px;
}

.query-list-has-sider {
  overflow: hidden;

  ::v-deep {
    .query-list-sider {
      position: relative;
      // width: 20%;
      float: left;

      .query-sider-title {
        line-height: 1em;
        margin-bottom: 14px;
        position: relative;

        .anticon {
          float: right;
          font-size: 18px;
          color: $color-primary;
        }
      }

      .query-sider-stretch {
        position: absolute;
        right: -26px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 98;

        .query-sider-stretch-bg {
          width: 26px;
          height: 70px;
        }

        .query-sider-stretch-arrow {
          position: absolute;
          right: 9px;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          transition: transform .3s ease-in;
        }
      }

      .stretch-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $pure-white;
        z-index: 98;
      }
    }

    .query-list-sider-stretch {
      .query-sider {
        padding: 0;
      }
    }

    .query-list-main {
      // width: 78.8%;
      float: right;
    }
  }
}
</style>
