<template>
  <a-modal
    title="选择人员"
    :dialog-style="{ top: '20px' }"
    :visible="visible"
    @ok="save"
    @cancel="$emit('close')"
    width="800px"
    class="user-picker"
  >
    <div class="left">
      <a-input-search placeholder="输入关键字搜索部门" @change="onChange" />
      <c-scroll-view :svType="2">
        <a-tree
          @expand="onExpand"
          :autoExpandParent="autoExpandParent"
          :expandedKeys="expandedKeys"
          :defaultExpandAll="true"
          :treeData="treeData"
          @select="onDepartmentSelect"
        >
          <template slot="title" slot-scope="{ title }">
            <span v-if="title.indexOf(searchValue) > -1">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span style="color: #f90">{{ searchValue }}</span>
              {{
                title.substr(title.indexOf(searchValue) + searchValue.length)
              }}
            </span>
            <span v-else>{{ title }}</span>
          </template>
        </a-tree>
      </c-scroll-view>
    </div>
    <div class="right">
      <!-- 查询表单标签( <c-query-param></c-query-param> ) -- start -->
      <a-form
        @keyup.enter.native="submitQuery"
        layout="inline"
        class="search-box"
      >
        <a-form-item label="人员搜索">
          <a-input
            placeholder="请输入姓名"
            v-model.trim="queryParam.nickName"
          ></a-input>
        </a-form-item>
        <div class="for-btn">
          <a-button
            type="primary"
            icon="search"
            :loading="queryLoading"
            @click="submitQuery"
            >查询</a-button
          >
          <a-button
            icon="reload"
            :loading="resetQueryLoading"
            @click="resetQuery"
            >重置</a-button
          >
        </div>
      </a-form>
      <!-- 列表记录 -->
      <c-query-result ref="queryResult">
        <a-table
          ref="table"
          class="records-table"
          :class="recordsTableClass"
          rowKey="id"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="false"
          :loading="loading"
          bordered
          :rowSelection="{
            columnWidth: 38,
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            type: choiceType ? 'checkbox' : 'radio',
          }"
          @change="handleTableChange"
        />
        <div class="query-result-footer">
          <div class="left" v-if="selectedRowKeys.length">
            <a-button @click="onClearSelected" size="small">清空</a-button>
            <span
              >已选择<em class="num">{{ selectedRowKeys.length }}</em
              >项</span
            >
          </div>
          <div class="right">
            <span
              >共<em class="num">{{ pagination.total }}</em
              >条记录</span
            >
            <a-pagination
              simple
              :show-quick-jumper="true"
              :pageSizeOptions="pagination.pageSizeOptions"
              :current="pagination.current"
              :total="pagination.total"
              showSizeChanger
              :pageSize="pagination.pageSize"
              v-model="pagination.current"
              @change="currentChange"
              @showSizeChange="pageSizeChange"
            />
          </div>
        </div>
      </c-query-result>
    </div>
  </a-modal>
</template>
<script>
import { cloneObject } from "@/utils/util";
import config from "@/config/defaultSettings";
import { queryList } from "@/mixins/queryList";

export default {
  mixins: [queryList],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    choiceType: {
      type: Boolean,
      default: true // 多选 - checkbox ； 单选 - radio
    }
  },
  data () {
    return {
      isInit: false,
      isLoadDataAtMounted: false,
      queryParam: {
        departmentId: undefined,
        nickName: "",
        isAccount: 2
      },
      recordsTableClass: config.recordsTable.defaultSizeClass,
      // 表头
      columns: [
        {
          ellipsis: true,
          title: "姓名",
          align: "left",
          dataIndex: "nickName"
        },
        {
          ellipsis: true,
          title: "帐号",
          align: "left",
          dataIndex: "userName"
        }
      ],
      url: {
        list: "/ofa-admin/admin/ofaUser/user/list"
      },
      expandedKeys: [],
      autoExpandParent: true,
      treeData: [], // 用来保存当前部门树显示的数据
      departmentData: [], // 用来保存接口返回的部门树的数据
      searchValue: "", // 部门树的检索框的输入内容
      treeNodesList: [] // 用来保存树节点的数组
    }
  },
  created () {
    this.loadTree();
    this.loadData();
  },
  methods: {
    // 重置
    resetQuery () {
      this.queryType = 0;
      this.queryParam.current = 1;
      this.queryParam.size = 10;
      this.queryParam.isAccount = 2;
      this.queryParam.nickName = "";
      this.handleType = 1;
      this.loadData();
    },
    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onChange (e) {
      const value = e.target.value;
      var expandedKeys = [];
      if (value === "") {
        // 空值默认展开第一级
        expandedKeys = [0];
      } else {
        expandedKeys = this.treeNodesList
          .map((item) => {
            if (item !== undefined) {
              if (item.title.indexOf(value) > -1) {
                return this.getNodeParentKey(item.title, this.treeData);
              }
            }
            return null;
          })
          .filter((item, i, self) => item !== null && self.indexOf(item) === i);
      }

      if (value != null && value.length > 0) {
        this.treeData = this.filterTreeData(
          cloneObject(this.departmentData),
          value
        );
      } else {
        this.$data.treeData = cloneObject(this.departmentData);
      }

      Object.assign(this, {
        expandedKeys: expandedKeys,
        searchValue: value,
        autoExpandParent: true
      });
    },
    // 获取所有部门
    loadTree () {
      let params = {
        treeType: 2,
        isAccount: 2
      };
      this.$axios({
        url: "/ofa-admin/admin/ofaDepartment/department/tree",
        method: "get",
        params
      }).then((res) => {
        if (res.code === 200 && res.data) {
          let arr = [];
          let temp = {};
          temp.title = "所有部门";
          temp.key = 0;
          temp.value = 0;
          temp.parentId = -1;
          temp.children = this.setTreeData(res.data);
          arr.push(temp);
          this.departmentData = arr;
          this.treeData = arr;
          this.generateNodesList(this.treeData);
          this.expandedKeys = [0]; // 默认展开第一级
        }
      });
    },
    // 处理数据格式使之符合菜单树型选择控件
    setTreeData (items) {
      let arr = [];
      for (let i = 0; i < items.length; i++) {
        let temp = {};
        temp.title = items[i].departmentName + "[" + items[i].memberCount + "]";
        temp.key = items[i].id;
        temp.value = items[i].id;
        temp.parentId = items[i].parentId;
        if (items[i].children && items[i].children.length > 0) {
          temp.children = this.setTreeData(items[i].children);
        }
        arr.push(temp);
      }
      return arr;
    },
    onDepartmentSelect (key) {
      if (key[0] !== 0) {
        // 0表示查询所有部门，则查询参数不增加departmentId
        this.$data.queryParam.departmentId = key[0];
      } else {
        delete this.$data.queryParam.departmentId;
      }
      this.loadData();
    },
    // 根据树的数据生成treeNodesList的数据
    generateNodesList (treeData) {
      for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i];
        const key = node.key;
        this.treeNodesList.push({ key, title: node.title });
        if (node.children) {
          this.generateNodesList(node.children, node.key);
        }
      }
    },
    // 通过key获取节点的父节点Key
    getNodeParentKey (key, treeData) {
      let parentKey;
      for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i];
        if (node.children) {
          if (node.children.some((item) => item.title === key)) {
            parentKey = node.key;
          } else if (this.getNodeParentKey(key, node.children)) {
            parentKey = this.getNodeParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    // 根据关键字过滤树的数据
    filterTreeData (treeData, value) {
      if (!treeData) {
        return null;
      }
      // eslint-disable-next-line no-array-constructor
      var newTreeData = new Array();
      var node = null;
      var children = null;
      var text = "";
      for (var i = 0; i < treeData.length; i++) {
        // 多个根节点开始遍历
        node = treeData[i];
        if (node.children) {
          children = node.children;
        }
        text = node.title;
        if (text.indexOf(value) > -1 || value === null) {
          newTreeData.push(node);
          continue;
        } else {
          if (children) {
            var newNodes = this.filterTreeData(node.children, value);
            if (newNodes != null && newNodes.length > 0) {
              node.children = newNodes;
              newTreeData.push(node);
            }
          }
        }
      }
      return newTreeData;
    },
    save () {
      this.$emit("save", { selectedRowKeys: this.selectedRowKeys, selectionRows: this.selectionRows });
      this.$emit('close');
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .ant-modal-body {
  display: flex;
}
.user-picker {
  .left {
    width: 30%;
  }
  .right {
    width: 70%;
    padding-left: 20px;
  }
  .for-btn {
    display: inline-block;
    .ant-btn {
      margin-left: 8px;
    }
  }
  .search-box {
    ::v-deep {
      .ant-form-item-label,
      .ant-form-item-control {
        line-height: 32px !important;
      }
    }
  }
  .query-result-footer {
    .right {
      text-align: right;
    }
  }
  .query-result-content {
    margin-top: 10px;
  }
}
</style>
