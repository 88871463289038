import Vue from "vue";
import {
  SIDEBAR_TYPE,
  APP_LANGUAGE
} from "@/store/mutation-types"
import { loadLanguageAsync } from '@/locales'
import api from "@/api"

const app = {
  state: {
    deployType: "", // 服务部署版本
    tenantProject: "", // 私有云租版
    sidebar: false, // 侧边栏
    device: "desktop",
    layoutSiderCollapsed: false, // Layout布局 侧边栏,
    layoutSiderWidth: "16.334%", // layoutSiderCollapsed ? "8.855%" : "16.334%"
    multiTab: true,
    messNum: 0,
    lang: "zh-CN",
    isFullScreen: false, // 是否全屏
    _antLocale: {},
    bigTitle: "",
    smallTitle: "",
    bigIcon: "",
    smallIcon: "",
    subsystems: [], // 应用池概念 - 所有子系统
    isIframePage: false // 子系统Iframe内嵌其他子系统页面
  },
  mutations: {
    SET_SMALL_ICON: (state, icon) => {
      state.smallIcon = icon
    },
    SET_BIG_ICON: (state, icon) => {
      state.bigIcon = icon
    },
    SET_SMALL_TITLE: (state, title) => {
      state.smallTitle = title
    },
    SET_BIG_TITLE: (state, title) => {
      state.bigTitle = title
    },
    SET_DEPLOYTYPE: (state, type) => {
      state.deployType = type || "platform";
    },
    SET_TENANTPROJECT: (state, type) => {
      state.tenantProject = type
    },
    SET_SIDEBAR_TYPE: (state, b) => {
      state.layoutSiderCollapsed = b
      state.layoutSiderWidth = b ? "8.855%" : "16.334%"
      Vue.ls.set(SIDEBAR_TYPE, b)
    },
    SET_FULLSCREEN: (state, b) => {
      state.isFullScreen = b
    },
    SET_LAYOUTSIDERWIDTH: (state, n) => {
      state.layoutSiderWidth = n
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_MESS_NUM: (state, messNum) => {
      state.messNum = messNum
    },
    [APP_LANGUAGE]: (state, lang, antd = {}) => {
      state.lang = lang
      state._antLocale = antd
      Vue.ls.set(APP_LANGUAGE, lang)
    },
    SET_SUBSYSTEM: (state, data) => {
      state.subsystems = data
    },
    SET_ISIFRAMEPAGE: (state, data) => {
      state.isIframePage = data;
    }
  },
  actions: {
    setSidebar ({ commit }, type) {
      commit("SET_SIDEBAR_TYPE", type)
    },
    ToggleDevice ({ commit }, device) {
      commit("TOGGLE_DEVICE", device)
    },
    MessNum ({ commit }, messnum) {
      commit("SET_MESS_NUM", messnum)
    },
    setLang ({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit(APP_LANGUAGE, lang)
        loadLanguageAsync(lang).then(() => {
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    },
    subsystemAction ({ commit }, data) {
      api.getMenuSubsystem().then(res => {
        if (res.code === 200) {
          commit("SET_SUBSYSTEM", res.data)
        }
      })
    }
  }
}

export default app
