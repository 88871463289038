
<script>
import { Modal } from 'ant-design-vue'
// 基于Andtdv的Modal组件新建拖拽功能。如果不需要拖拽功能给组件传:drag="false"
export default {
  name: "AModal",
  components: {
    Modal
  },
  props: Modal.props,
  model: {
    prop: "visible",
    event: "change"
  },
  render () {
    const {
      $listeners,
      $slots,
      $scopedSlots,
      $attrs
    } = this;

    const drag = $attrs.drag !== false
    const directives = drag ? [
      {
        'name': 'drag'
      }
    ] : []

    const dialogProps = {
      props: {
        ...this.$props
      },
      on: {
        ...$listeners
      },
      scopedSlots: {
        ...$scopedSlots
      },
      slot: {
        ...$slots
      },
      attrs: $attrs,
      directives
    };
    return <Modal {...dialogProps}></Modal>;
  }
}
</script>
