import Vue from "vue"
import Router from "vue-router"
import constRoutes from "./routes"

// [fix bug] vue router 报错： Uncaught (in promise) NavigationDuplicated {_name:""NavigationDuplicated"
// hack router push callback
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// 调用
Vue.use(Router)

const createRouter = () => new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: constRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// 创建 router 实例
export default router
