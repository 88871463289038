<template>
  <div class="password-strength" v-show="password">
    <span v-for="i in 3" :key="i" :style="i <= passwordStrength ? {'background-color': passwordStrengthColor} : {}"></span>
  </div>
</template>

<script>
export default {
  name: "PasswordStrength",
  props: {
    // 判断强度的密码值
    password: {
      required: true,
      type: String,
      default: ""
    },
    // 密码强度弱颜色值
    dangerColor: {
      type: String,
      default: "#f56c6c"
    },
    // 密码强度中颜色值
    warningColor: {
      type: String,
      default: "#fb864e"
    },
    // 密码强度高颜色值
    securityColor: {
      type: String,
      default: "#09c3c7"
    },
    // 密码规定最小长度
    minimumLen: {
      type: Number,
      default: 8
    }
  },
  data: function () {
    return {
      passwordStrength: 0, // 密码强度，分为1、2、3、4,1对应弱，2对应中，3、4对应高
      passwordStrengthColor: "gray" // 密码强度展示颜色
    }
  },
  methods: {
    // 判断密码强度
    handlePasswordStrength (password) {
      // 强度判断规则：是否有数字、小写字母、大写字母、特殊字符
      // 用于一项则该项为最终密码强度赋值1.不存在赋值0
      let isNumber, isLowerLetter, isCapital, isSymbol;
      // 密码长度小于规定最小长度时强度都为0
      if (password.length < this.minimumLen) {
        this.passwordStrength = 1;
      } else {
        isNumber = /\d/g.test(password) ? 1 : 0;
        isLowerLetter = /[a-z]/g.test(password) ? 1 : 0;
        isCapital = /[A-Z]/g.test(password) ? 1 : 0;
        isSymbol = /[~!@#$%^&*_./?]/g.test(password) ? 1 : 0;
        this.passwordStrength = isNumber + isLowerLetter + isCapital + isSymbol;
      }
      // 赋值密码强度颜色
      if (this.passwordStrength === 0) {
        this.passwordStrengthColor = "gray";
      } else if (this.passwordStrength === 1) {
        this.passwordStrengthColor = this.dangerColor;
      } else if (this.passwordStrength === 2) {
        this.passwordStrengthColor = this.warningColor;
      } else {
        this.passwordStrengthColor = this.securityColor;
      }
    }
  },
  watch: {
    // 监听密码值改变，调用密码强度判断函数
    password: {
      immediate: true,
      handler: function (newVal) {
        this.handlePasswordStrength(newVal);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password-strength {
  text-align: right;
  line-height: 1;
  font-size: 0;
  span {
    display: inline-block;
    padding-top: 2.4%;
    width: 13%;
    background-color: gray;
    margin-left: 2px;
    transition: all .3s ease-in;
  }
}
</style>
