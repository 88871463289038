<template>
  <a-modal
    :title="modalTitle"
    :height="400"
    :width="600"
    v-model="isVisible"
    wrapClassName="custom-ant-modal">
    <a-form :form="form">

      <a-form-item label="导入数据" v-bind="formItemLayout">
        <a-upload
          :fileList="fileList"
          :customRequest="uploadFile"
          :accept="uploadFileAccept"
          @change="handleChange"
        >
          <a-button>
            <a-icon type="upload" />{{ uploadFilePlaceholder }}
          </a-button>
          <span style="margin-left: 10px;">支持的文件格式: {{ fileFormatsSupported }}</span>
        </a-upload>
      </a-form-item>
      <a-form-item
        v-if="!isBlank(content.exportUrl)"
        label="下载模板"
        v-bind="formItemLayout">
        <a-button type="dashed"  @click="dowloadExcel()">
          点击下载
        </a-button>
      </a-form-item>
      <a-form-item
        style="margin-top: -20px;"
        v-if="uploadFileType === 'excel' && columns.length > 0"
        label="导入详情"
        v-bind="formItemLayout">
        <template>
          <p>导入总条数：<span>{{message.totalNum}}</span></p>
          <p>导入成功数：<span>{{message.successNum}}</span></p>
          <p>导入失败数：<span>{{message.errorNum}}</span></p>
          <p>导入进度条：
            <a-progress style="width: 150px;" :percent="message.progress" size="small" />
          </p>
          <p>导入状态：
            <template v-if="importStatus">
              <a-spin :spinning="!message.status" style="margin-right: 10px;"/>
              <span v-if="!message.status">进行中</span>
              <span v-if="message.status">完成</span>
            </template>
            <template v-else>
              <span style="color: red">上传进度异常</span>
            </template>
          </p>
        </template>
      </a-form-item>
    </a-form>
    <template slot="footer">
      <a-button @click="close" type="primary">
        关闭
      </a-button>
      <a-button
        style="margin-left: 10px;"
        @click="errorVisible = true"
        v-if="uploadFileType === 'excel' && dataSource.length > 0"
        :disabled="dataSource.length === 0"
      >
        查看导入错误
      </a-button>
    </template>

    <!-- 查看错误消息 -->
    <a-modal
      title="导入错误数据"
      :height="500"
      :width="1200"
      :visible="errorVisible"
      @cancel="errorVisible = false"
    >
      <div ref="tableScroll" style="height: 400px;">
        <!-- table区域 -->
        <ResizableTable
          ref="table"
          bordered
          class="custom-table table-errorlist"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="false"
          rowKey="id"
          :scroll="{ x: 720, y: 360 }">

          <template slot="sequenceNumber" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>

        </ResizableTable>
      </div>
      <div slot="footer">
        <a-button key="back" @click="errorVisible = false">关闭</a-button>
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
// @ is an alias to /src
import { isBlank } from "@/utils"
import { dowloadExcel } from "@/utils/util"
import { axios, resMsg } from "@/utils/http";
import { apiRequestPreproccess, replaceBraceStr } from "@/views/devAppOnline/module/tools"
import { ResizableTable } from '@/components/Table';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
export default {
  name: "ImportModal",
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    ResizableTable
  },
  computed: {
    // 是否显示弹框
    isVisible: {
      get () {
        return this.visible;
      },
      set (v) {
        this.close();
      }
    }
  },
  deactivated () {
    this.clearInterval();
  },
  beforeDestroy () {
    this.clearInterval();
  },
  data () {
    return {
      visible: false, // 保存当前弹窗是否显示
      form: this.$form.createForm(this),
      columns: [],
      dataSource: [],
      formItemLayout,
      errorVisible: false,
      content: {}, // 配置信息
      timeId: "", // 定时任务id
      mid: "", // 消息id
      message: {
        totalNum: 0, // 总条数
        successNum: 0, // 成功数
        errorNum: 0, // 失败数
        status: false, // true完成 false进行中
        progress: 30, // 进度
        errorList: [] // 错误数据
      }, // 上传成功返回的消息
      importStatus: 1, // 上传进度查询: 0: 出错，1: 正常
      fileList: [],
      uploadFileType: "excel", // 上传文件类型
      uploadFileAccept: ".xlsx,.xls", // 支持上传文件
      modalTitle: "导入Excel", // 弹框标题
      uploadFilePlaceholder: "上传Excel文件",
      fileFormatsSupported: ".xlsx/.xls"
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    isBlank,
    // 显示弹窗
    show () {
      this.visible = true;
      this.fileList = [];
      this.init();
    },
    // 初始化配置信息
    init () {
      this.content = this.config;
      this.setModalView(this.content);
    },

    // Modal 对话框 视图
    setModalView (content) {
      if (content.fileType === "csv") {
        this.uploadFileType = "csv";
        this.uploadFileAccept = ".csv";
        this.modalTitle = "导入CSV文件";
        this.uploadFilePlaceholder = "上传CSV文件";
        this.fileFormatsSupported = ".csv"
      }
      if (!isBlank(content.title)) {
        this.modalTitle = content.title;
      }
    },

    // 下载模板
    dowloadExcel () {
      let url = this.content.exportUrl;
      let type = this.content.type;
      // 预加工 内部接口 或者 外部接口 http 接口请求
      let api = apiRequestPreproccess(type, "get", url, null);
      let apiObj = Object.assign(api, { responseType: "blob" })
      axios({ ...apiObj }).then(res => {
        // axios.get(url, { responseType: "blob" }).then((res) => {
        // 返回的是二进制流，所以不需要判断code
        dowloadExcel(res, this.config.title+"模板.xlsx");
      })
    },

    // 选择上传文件
    handleChange ({ file, fileList }) {
      if (this.verifyFileType(file)) {
        this.fileList = fileList.slice(-1);
      }
    },

    // 自定义封面的上传事件
    uploadFile (data) {
      if (!this.verifyFileType(data.file)) {
        return;
      }
      let formData = new FormData();
      formData.append("file", data.file);
      data.onProgress();
      let type = this.content.type;
      let url = this.content.apiUrl;
      if (this.record) {
        url = replaceBraceStr(this.content.apiUrl, this.record);
      }
      this.columns = [];
      // 预加工 内部接口 或者 外部接口 http 接口请求
      let api = apiRequestPreproccess(type, "post", url, formData);
      axios({ ...api }).then(res => {
      // axios({
      //   method: "post",
      //   url: this.content.apiUrl,
      //   data: formData
      // }).then((res) => {
        if (res.code === 200) {
          data.onSuccess(); // 上传成功
          this.uploadFileResult(res);
        } else {
          data.onError(); // 上传失败
          resMsg("error", res.msg);
        }
      })
    },

    // 上传文件结果
    uploadFileResult (res) {
      if (this.uploadFileType === "excel") {
        resMsg("success", res.msg);
        // if (isBlank(res.data)) {
        //   resMsg(res.msg);
        // } else {
        //   let message = res.data.message;
        //   if (!isBlank(message)) {
        //     this.mid = message.mid;
        //     this.message = {
        //       totalNum: message.totalNum || 0,
        //       successNum: 0,
        //       errorNum: 0,
        //       status: false,
        //       progress: 0
        //     }
        //     this.createInterval();
        //     this.initColumns(message.columns);
        //     resMsg("success", "上传成功，数据导入进行中！");
        //   } else {
        //     resMsg("warning", "请按照配置说明返回【message】结构数据");
        //   }
        // }
      } else if (this.uploadFileType === "csv") {
        resMsg("success", res.msg);
      }
    },

    // 组合错误列表的列头
    initColumns (data) {
      data = data || [
        { fieldName: "用户名称", fieldCode: "name" },
        { fieldName: "用户编号", fieldCode: "code" },
        { fieldName: "错误原因", fieldCode: "error" }
      ]
      let columns = [
        {
          ellipsis: true,
          title: "序号",
          align: "center",
          fixed: "left",
          width: 60,
          dataIndex: "sequenceNumber",
          scopedSlots: { customRender: "sequenceNumber" }
        }
      ];
      for (const i in data) {
        columns.push({
          ellipsis: true,
          title: data[i].fieldName,
          align: "center",
          dataIndex: data[i].fieldCode,
          width: data[i].width
        });
      }
      columns[columns.length - 1].width = 120;
      columns[columns.length - 1].fixed = "right";
      this.dataSource = [];
      this.columns = columns;
    },

    // 创建定时任务
    createInterval () {
      !isBlank(this.timeId) && this.clearInterval();
      this.timeId = setInterval(this.getProgress, 1000);
    },

    // 获取导入进度信息
    getProgress () {
      let url = this.content.progressUrl;
      let params = { mid: this.mid };
      axios.get(url, { params }).then((res) => {
        if (res.code === 200) {
          let message = res.msg;
          if (!isBlank(message)) {
            this.message = {
              totalNum: message.totalNum || this.message.totalNum,
              successNum: message.successNum || 0,
              errorNum: message.errorNum || 0,
              status: message.status || false
            }
            this.dataSource = message.errorList || [{ name: "张三", code: "123", error: "用户编号有误！" }];
            let progress = parseInt(((this.message.successNum + this.message.errorNum) / this.message.totalNum) * 100);
            this.message.progress = progress;
            // 销毁定时任务
            if (this.message.status) {
              this.clearInterval();
              this.$emit("modalFormOk"); // 刷新列表
            }
          } else {
            this.importStatus = 0;
            this.clearInterval();
            this.$error({
              title: "提示",
              content: "请按照配置说明返回【message】结构数据"
            });
          }
        } else {
          this.importStatus = 0;
          this.clearInterval();
          this.$error({
            title: "提示",
            content: res.msg
          });
        }
      }).catch(() => {
        this.importStatus = 0;
        this.clearInterval();
      })
    },

    // 关闭 Modal 对话框
    close () {
      this.clearInterval();
      this.visible = false;
      this.$emit("close")
    },

    // 销毁定时任务
    clearInterval () {
      window.clearInterval(this.timeId);
    },

    // 验证文件格式
    verifyFileType (file) {
      let fileUrl = file.name.toLowerCase();
      let uploadFileType = this.uploadFileType;
      let b = false;
      if (uploadFileType === "excel") {
        // 上传excel文件
        if (fileUrl.indexOf(".xls") === -1 && fileUrl.indexOf(".xlsx") === -1) {
          this.$message.warning("只能上传 .xls、.xlsx 格式的文件!");
        } else {
          b = true;
        }
      } else if (uploadFileType === "csv") {
        // 上传csv文件
        if (fileUrl.indexOf(".csv") === -1) {
          this.$message.warning("只能上传 .csv 格式的文件!");
        } else {
          b = true;
        }
      }
      return b;
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep {
  .table-errorlist {
    .ant-table-tbody tr td {
      white-space: break-spaces !important;
    }
  }
}
</style>
