import { axios, resMsg } from "@/utils/http"
import { filterObj } from "@/utils/util"
import { isBlank } from "@/utils/dataType"
import { parameterCacheQueue } from "@/utils/cacheQuery.js"
export const queryList = {
  data () {
    return {
      queryParam: {}, // 普通查询条件
      superQueryParams: "", // 高级查询条件

      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      },

      // 排序参数
      isorter: {
        column: "",
        order: ""
      },

      filters: {}, // 筛选参数

      // 分页
      pagination: {
        pageSizeOptions: ["30", "60", "100", "150", "200"],
        current: 1,
        pageSize: 30,
        total: 0
      },
      dataSource: [], // 数据源
      loading: false, // table 加载状态
      selectedRowKeys: [], // table 选中 keys
      selectionRows: [], // table选中records

      queryLoading: false,
      resetQueryLoading: false,
      queryType: 1, // 查询类型，0: 重置查询，1: 查询
      handleType: 0, // 查询触发方式， 0: 其他，1: 点击查询或重置按钮
      isInit: true, // 初始化缓存查询参数
      isLoadDataAtMounted: true // 页面加载后马上查询
    }
  },

  mounted () {
    if (this.isLoadDataAtMounted) {
      this.loadData();
    }
  },

  methods: {
    // 高级查询方法
    handleSuperQuery (arg) {
      // 高级查询方法
      if (!arg) {
        this.superQueryParams = ""
        this.superQueryFlag = false
      } else {
        this.superQueryFlag = true
        this.superQueryParams = JSON.stringify(arg)
      }
      this.loadData()
    },

    // 查询参数
    getQueryParams () {
      // 获取查询条件
      let sqp = {}
      if (this.superQueryParams) {
        sqp["superQueryParams"] = encodeURI(this.superQueryParams)
      }
      var param = Object.assign(sqp, this.queryParam, this.isorter, this.filters);
      param.pageNum = this.pagination.current;
      param.pageSize = this.pagination.pageSize;
      return filterObj(param);
    },

    // 加载数据
    loadData () {
      // 回显查询缓存参数
      if (this.isInit) {
        this.isInit = false;
        let queryParams = JSON.parse(sessionStorage.getItem(this.$route.name));
        if (!isBlank(queryParams)) {
          let { pageNum, pageSize } = queryParams;
          delete queryParams.pageNum;
          delete queryParams.pageSize;
          this.pagination.current = pageNum;
          this.pagination.size = pageSize;
          this.queryParam = queryParams;
        }
      }
      if (!this.url.list) {
        // this.$message.error("请设置url.list属性!");

        this.changeLoadingBtnStatus(true);
        setTimeout(() => {
          this.$message.error("请设置url.list属性!");
          this.changeLoadingBtnStatus(false);
          this.handleType = 0;
        }, 2000)
        return;
      }
      var params = this.getQueryParams(); // 查询条件
      this.cacheQuery(params); // 缓存查询参数
      this.changeLoadingBtnStatus(true);
      this.loading = true;
      axios.get(this.url.list, { params })
        .then(res => {
          if (res.code === 200) {
            let resData = res.data || [];
            this.dataSource = resData.hasOwnProperty("Rows") ? resData.Rows : resData;
            this.pagination.total = resData.hasOwnProperty("total") ? Number(resData.total) : resData.length;
          } else {
            resMsg("error", res.msg, "查询失败！");
          }
        })
        .finally(() => {
          // always executed
          this.changeLoadingBtnStatus(false);
          this.handleType = 0;
          this.loading = false;
          this.selectedRowKeys = [];
        });
    },

    // 缓存查询条件
    cacheQuery (params) {
      sessionStorage.setItem(this.$route.name, JSON.stringify(params));
      parameterCacheQueue("setItem", this.$route.name);
    },

    // 改变 changeLoadingBtnStatus 状态
    changeLoadingBtnStatus (b) {
      if (this.handleType) {
        if (this.queryType) {
          this.queryLoading = b
        } else {
          this.resetQueryLoading = b
        }
      }
    },

    // 新建
    handleAdd () {
      this.$refs.modalForm.title = "新建";
      this.$refs.modalForm.add();
    },

    // 删除
    handleDel (id) {
      if (!this.url.delete) {
        this.$message.error("请设置url.delete属性!")
        return;
      }
      // const clearLoading = this.$message.loading("正在删除..", 0);
      axios({ method: "delete", url: this.url.delete + '/' + id }).then((res) => {
        if (res.code === 200) {
          resMsg("success", res.msg, "删除成功！");
          this.loadData();
        } else {
          resMsg("error", res.msg, "删除失败！");
        }
      })
    },

    // 批量删除
    batchDel () {
      if (!this.url.delete) {
        this.$message.error("请设置url.delete属性!")
        return;
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请至少选择一条记录！");
      } else {
        this.$confirm({
          title: "确认删除",
          content: "此操作将删除选中数据, 确定删除?",
          onOk: () => {
            let ids = ''
            for (let i = 0; i < this.selectedRowKeys.length; i++) {
              ids += this.selectedRowKeys[i];
              if (i < this.selectedRowKeys.length - 1) {
                ids += ',';
              }
            }
            // const clearLoading = this.$message.loading("正在删除..", 0);
            axios({ method: "delete", url: this.url.delete + '/' + ids }).then((res) => {
              if (res.code === 200) {
                resMsg("success", res.msg, "删除成功！");
                this.loadData();
                this.onClearSelected();
              } else {
                resMsg("error", res.msg, "删除失败！");
              }
            })
              .finally(() => {
                // always executed
                // clearLoading()
              });
          }
        });
      }
    },

    // 修改
    handleEdit (record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑";
    },

    // 详情
    handleDetail (record) {
      this.$refs.modalDetail.detail(record);
      this.$refs.modalDetail.title = "详情";
    },

    // 查询
    submitQuery () {
      this.queryType = 1;
      this.handleType = 1;
      this.pagination.pageNum = 1;
      this.pagination.current = 1;
      this.loadData()
    },

    // 重置
    resetQuery () {
      this.queryType = 0;
      this.queryParam = {
        current: 1,
        size: 30
      }
      this.pagination.current = 1;
      this.pagination.pageSize = 30;
      this.handleType = 1;
      this.loadData();
    },

    // 分页、排序、筛选变化时触发
    // TODO 筛选
    handleTableChange (pagination, sorter) {
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = sorter.order === "ascend" ? "asc" : "desc"
      }
      this.pagination = pagination;
      this.loadData();
    },

    // 新建/修改 成功时
    modalFormOk () {
      // 重载列表
      this.loadData();
    },

    // 页码改变
    currentChange (page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.loadData();
    },

    // pageSize 变化
    pageSizeChange (current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.loadData();
    },

    // 选中项发生变化时的回调
    onSelectChange (selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    // 清空
    onClearSelected () {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    }

  }
}
