/*!
 * @file utils/compare.js
 * @desc 用于数据校对变化、判断是否相同等
 */

import isEqualWith from "lodash.isequalwith"
import { isBlank } from "./dataType"

/**
 * 判断两个变量（对象键值对、数组索引值、其他）是否存在不同
 * @param {*} value 用来比较的值
 * @param {*} other 另一个用来比较的值
 * @returns {Boolean}
 */

export function isDifferent (value, other) {
  function customizer (objValue, othValue) {
    // 当为空值时定义为相同
    if (isBlank(objValue) && isBlank(othValue)) {
      return true;
    }
  }
  return !isEqualWith(value, other, customizer);
}
