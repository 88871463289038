export default ({ request, baseURL }) => ({

// 查询字典类型列表
  listDictType(params) {
    return request({
      url: '/system/dict/type/list',
      method: 'get',
      params
    })
  },

// 查询字典类型详细
  getDictType(dictId) {
    return request({
      url: '/system/dict/type/' + dictId,
      method: 'get'
    })
  },

// 新增字典类型
  addDictType(data) {
    return request({
      url: '/system/dict/type',
      method: 'post',
      data
    })
  },

// 修改字典类型
  updateDictType(data) {
    return request({
      url: '/system/dict/type',
      method: 'put',
      data
    })
  },

// 删除字典类型
  delDictType(dictId) {
    return request({
      url: '/system/dict/type/' + dictId,
      method: 'delete'
    })
  },

// 刷新字典缓存
  refreshCacheDict() {
    return request({
      url: '/system/dict/type/refreshCache',
      method: 'delete'
    })
  },

// 获取字典选择框列表
  optionselectDict() {
    return request({
      url: '/system/dict/type/optionselect',
      method: 'get'
    })
  }
})
