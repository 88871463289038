<script>
import events from "./events"
import i18n from "@/locales"
import { parameterCacheQueue } from "@/utils/cacheQuery.js"
import qs from "qs";
// 检测 path 是否已经存在
const hasPath = (items, fullPath) => {
  let b = false;
  let path = fullPath.split("?")[0];
  for (let i = 0; i < items.length; i++) {
    if (items[i].fullPath.split("?")[0] === path) {
      b = true;
      break;
    }
  }
  return b;
}
// 检测 fullPath 是否已经存在
const hasFullPath = (items, fullPath) => {
  let b = false;
  for (let i = 0; i < items.length; i++) {
    if (items[i].fullPath === fullPath) {
      b = true;
      break;
    }
  }
  return b;
}
// 替换 fullPath
const replaceFullPath = (items, fullPath) => {
  let path = fullPath.split("?")[0];
  for (let i = 0; i < items.length; i++) {
    if (items[i].fullPath.split("?")[0] === path) {
      items[i].fullPath = fullPath;
      break;
    }
  }
}

export default {
  name: "MultiTab",
  data () {
    return {
      pages: [{
        name: "myHome",
        path: "/home",
        fullPath: "/home",
        title: "首页",
        i18nTitle: "frame.menu.homePage",
        closable: false
      }],
      activeKey: "",
      newTabIndex: 0
    }
  },
  created () {
    // bind event
    events.$on("open", val => {
      if (!val) {
        throw new Error(`multi-tab: open tab ${val} err`)
      }
      this.activeKey = val
    }).$on("close", val => {
      if (!val) {
        this.closeThat(this.activeKey)
        return
      }
      this.closeThat(val)
    })
    this.init();
  },
  methods: {
    // 初始化
    init () {
      let sessionPages = JSON.parse(sessionStorage.getItem("PAGE_TABS"));
      if (sessionPages && sessionPages.length) {
        this.pages = sessionPages;
      }
      this.add(this.$route);
    },

    onEdit (targetKey, action) {
      this[action](targetKey)
    },

    // 切换
    tabsChange (activeKey) {
      let path = activeKey.split("?")[0];
      let query = qs.parse(activeKey.split("?")[1]);
      this.$router.push({ path, query });
    },

    add (route) {
      let { name, path, fullPath, meta } = route;
      let customTitle = meta.customTitle;
      let title = meta.title;
      let i18nTitle = meta.i18nTitle;
      let pages = this.pages;
      if (hasPath(pages, fullPath)) {
        if (!hasFullPath(pages, fullPath)) {
          replaceFullPath(pages, fullPath);
          // 缓存到当前会话
          sessionStorage.setItem("PAGE_TABS", JSON.stringify(pages));
        }
      } else {
        pages.push({ name, path, fullPath, customTitle, title, i18nTitle, closable: true });
        // 缓存到当前会话
        sessionStorage.setItem("PAGE_TABS", JSON.stringify(pages));
        this.$store.commit("SET_TABPAGES", JSON.parse(JSON.stringify(this.pages)));
        this.selectedLastPath()
      }
      this.activeKey = fullPath;
    },

    remove (targetKey) {
      if (targetKey === "/home") {
        // 不移除首页 tab
        return;
      }
      this.pages = this.pages.filter(page => page.fullPath !== targetKey);
      // 缓存到当前会话
      sessionStorage.setItem("PAGE_TABS", JSON.stringify(this.pages));
      this.$store.commit("SET_TABPAGES", JSON.parse(JSON.stringify(this.pages)));

      // 判断当前标签是否关闭，若关闭则跳转到最后一个还存在的标签页
      let isClosed = true;
      this.pages.forEach((page) => {
        if (page.fullPath === this.activeKey) {
          isClosed = false;
        }
      });
      if (isClosed) {
        this.selectedLastPath();
      }

      // 清除标签页面参数缓存
      let { route: { name } } = this.$router.resolve(targetKey)
      // 清除统一应用的多级页面缓存
      sessionStorage.removeItem(`${name}-signKey`)
      // 清除参数队列中该key
      parameterCacheQueue("remove", `${name}-signKey`)
      // 遍历关闭标签页多级页面参数缓存并清除
      let sign = 1;
      while (sign) {
        if (!sessionStorage.getItem(`${name}-${sign - 1}`)) break;
        sessionStorage.removeItem(`${name}-${sign - 1}`);
        parameterCacheQueue("remove", `${name}-${sign - 1}`) // 清除参数队列中该key
        sign++;
      }
      this.handleCloseTabPage(); // 关闭tab页面标签页时触发此全局方法

      if (targetKey === this.activeKey) {
        this.tabsChange(targetKey);
      }
    },

    // 选中最后一项
    selectedLastPath () {
      let lastIndex = this.pages.length - 1;
      this.activeKey = this.pages[lastIndex].fullPath;
    },

    // content menu
    closeThat (e) {
      // 判断是否为最后一个标签页，如果是最后一个，则无法被关闭
      if (this.pages.length > 1) {
        this.remove(e)
      } else {
        this.$message.info("这是最后一个标签了, 无法被关闭")
      }
    },
    closeLeft (e) {
      let currentIndex = 0;
      this.pages.forEach((page, i) => {
        if (page.fullPath === e) {
          currentIndex = i;
        }
      });
      if (currentIndex > 0) {
        this.pages.forEach((item, index) => {
          if (index < currentIndex) {
            this.remove(item.fullPath)
          }
        })
      } else {
        this.$message.info("左侧没有标签")
      }
    },
    closeRight (e) {
      let currentIndex = 0;
      this.pages.forEach((page, i) => {
        if (page.fullPath === e) {
          currentIndex = i;
        }
      });
      if (currentIndex < (this.pages.length - 1)) {
        this.pages.forEach((item, index) => {
          if (index > currentIndex) {
            this.remove(item.fullPath)
          }
        })
      } else {
        this.$message.info("右侧没有标签")
      }
    },
    closeAll (e) {
      let currentIndex = 0;
      this.pages.forEach((page, i) => {
        if (page.fullPath === e) {
          currentIndex = i;
        }
      });
      this.pages.forEach((item, index) => {
        if (index !== currentIndex) {
          this.remove(item.fullPath)
        }
      })
    },
    closeMenuClickByButton (key) {
      this.closeMenuClick(key, this.$route.path)
    },
    closeMenuClick (key, route) {
      this[key](route)
    },
    renderTabPaneMenu (e) {
      let closeThat = i18n.t('frame.tabs.nav.close.current');
      let closeLeft = i18n.t('frame.tabs.nav.close.left');
      let closeRight = i18n.t('frame.tabs.nav.close.right');
      let closeAll = i18n.t('frame.tabs.nav.close.all');
      return (
        <a-menu {...{ on: { click: ({ key, item, domEvent }) => { this.closeMenuClick(key, e) } } }}>
          <a-menu-item key="closeThat">{ closeThat }</a-menu-item>
          <a-menu-item key="closeLeft">{ closeLeft }</a-menu-item>
          <a-menu-item key="closeRight">{ closeRight}</a-menu-item>
          <a-menu-item key="closeAll">{ closeAll }</a-menu-item>
        </a-menu>
      )
    },
    // render
    renderTabPane (meta, keyPath) {
      const menu = this.renderTabPaneMenu(keyPath)
      let title = "";
      if (meta.titleI18n) {
        // 国际化多语言
        title = i18n.t(meta.titleI18n);
      } else {
        title = meta.customTitle || meta.title;
      }

      return (
        <a-dropdown overlay={menu} trigger={["contextmenu"]}>
          <span style={{ userSelect: "none" }}>{ title }</span>
        </a-dropdown>
      )
    }
  },
  watch: {
    '$route': function (route) {
      this.add(route);
    }
  },
  render () {
    const { onEdit, $data: { pages } } = this
    const panes = pages.map(page => {
      let isClosable = true;
      if (page.path === "/home") {
        isClosable = false
      } else {
        isClosable = pages.length > 1
      }
      return (
        <a-tab-pane
          style={{ height: 0 }}
          tab={this.renderTabPane({ title: page.title, i18nTitle: page.i18nTitle }, page.fullPath)}
          key={page.fullPath} closable={isClosable}
          closable={page.closable}
        >
        </a-tab-pane>)
    })

    return (
      <a-tabs
        class="ofa-ant-tabs"
        tab-position="top"
        hideAdd
        type={"editable-card"}
        v-model={this.activeKey}
        on-change={this.tabsChange(this.activeKey)}
        {...{ on: { edit: onEdit } }}>
        {panes}
      </a-tabs>
    )
  }
}

</script>

<style lang="scss" scoped>
.ofa-ant-tabs {
  position: absolute;
  width: calc(100% - 236px);
  margin-top: -20px;
  top: 50%;
  ::v-deep {
    .ant-tabs-bar {
      border-bottom: none;
      padding: 0 !important;
      margin: 0 !important;
      .ant-tabs-tab {
        background: $pure-white;
        border-radius: 4px;
        border: none;
        &.ant-tabs-tab-active {
          background-color: #f4f5fb;
        }
      }
    }
  }
}
</style>
