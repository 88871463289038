/**
 * Inject NProgress into Vue component
 * Show progressbar when route & ajax
 */

import router from "@/router"
import { nprogress } from "@/utils"

export default Vue => {
  // Add nprogress to route
  router.beforeEach((to, from, next) => {
    nprogress.start()
    next()
  })
  router.afterEach(() => {
    nprogress.done()
  })

  // mount the nprogress to Vue component instance
  Object.defineProperties(Vue.prototype, {
    $nprogress: { get: () => nprogress }
  })
}
