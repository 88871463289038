// 添加、删除缓存参数队列key
export function parameterCacheQueue (operation, key) {
  let parameterCacheQueue = sessionStorage.getItem("parameterCacheQueue") ? sessionStorage.getItem("parameterCacheQueue")?.split("&") : [];
  switch (operation) {
    case "remove":
      parameterCacheQueue.length && (parameterCacheQueue = parameterCacheQueue.filter(item => item !== key));
      break;
    case "setItem":
      parameterCacheQueue.indexOf(key) === -1 && parameterCacheQueue.push(key);
      break;
    case "clear":
      parameterCacheQueue.length = 0;
      break;
  }
  sessionStorage.setItem(
    "parameterCacheQueue",
    parameterCacheQueue.join("&"));
}

// 清除配置页面参数缓存
export function clearParameterCache () {
  let parameterCacheQueue = sessionStorage.getItem("parameterCacheQueue") ? sessionStorage.getItem("parameterCacheQueue").split("&") : [];
  if (parameterCacheQueue.length) {
    parameterCacheQueue.forEach(item => sessionStorage.removeItem(item));
    sessionStorage.removeItem("parameterCacheQueue");
  }
}
