/**
 * Vuex docs
 * https://vuex.vuejs.org/zh-cn
 * https://github.com/vuejs/vue-hackernews-2.0
 */

import Vue from "vue"
import Vuex from "vuex"

// // Make sure state writeable
// import * as state from './state' // prop readonly
import state from "./state"
import * as getters from "./getters"
import * as mutations from "./mutations"
import * as actions from "./actions"
import * as modules from "./modules"

Vue.use(Vuex)

// 不要在发布环境下启用严格模式！严格模式会深度监测状态树来检测不合规的状态变更——请确保在发布环境下关闭严格模式，以避免性能损失。
const strict = process.env.NODE_ENV !== "production"

const plugins = []

const store = new Vuex.Store({ state, getters, mutations, actions, modules, strict, plugins })

// ## Hot module replacement
if (module.hot) {
  module.hot.accept([
    "./getters",
    "./mutations",
    "./actions",
    "./modules/app",
    "./modules/permission",
    "./modules/user"
  ], () => {
    store.hotUpdate({
      getters: require("./getters"),
      mutations: require("./mutations"),
      actions: require("./actions"),
      modules: {
        app: require("./modules/app"),
        permission: require("./modules/permission"),
        user: require("./modules/user")
      }
    })
  })
}

export default store
