import Vue from "vue"
import {
  Affix,
  AutoComplete,
  Alert,
  Avatar,
  Space,
  Anchor,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Collapse,
  Cascader,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Descriptions,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  LocaleProvider,
  Menu,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Statistic,
  Switch,
  Table,
  Transfer,
  Tree,
  TreeSelect,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Drawer,
  Skeleton,
  Empty,
  Carousel,
  Calendar,
  Steps,
  FormModel
} from "ant-design-vue"

import message from "@/components/resetAntdv/Message";

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;

const components = [
  Affix,
  AutoComplete,
  Alert,
  Avatar,
  Space,
  Anchor,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Collapse,
  Cascader,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Descriptions,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  LocaleProvider,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Statistic,
  Switch,
  Table,
  Transfer,
  Tree,
  TreeSelect,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Skeleton,
  Empty,
  Carousel,
  Calendar,
  Steps,
  FormModel
];
const install = function (Vue) {
  components.map(component => {
    Vue.use(component);
  });

  Vue.prototype.$message = message;
};
/* istanbul ignore if */
if (typeof window !== "undefined") {
  install(Vue);
}
