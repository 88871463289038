import Vue from "vue";
import { message } from 'ant-design-vue';

/*
  h: 是content自带的参数，表示createElement
  content：文本内容
*/
const createElementVNode = (h, content) => {
  // content 提示内容 string| VNode |(h) => VNode
  let innerContent;
  if (typeof content === 'object') {
    // 内容是 VNode
    innerContent = content;
  } else if (typeof content === 'function') {
    // 内容是 (h) => VNode
    // 创建组件构造器
    const Component = Vue.extend({
      render: content
    });
    // 创建组件的虚拟节点
    innerContent = h(Component);
  } else {
    // 内容是 string
    innerContent = h('span', content);
  }

  // 自定义关闭图标
  const innerIcon = h('a-icon', {
    style: { color: 'rgba(54, 64, 100, 0.85)', cursor: 'pointer', position: 'absolute', top: '4px', right: '4px', fontSize: '14px', margin: 0 },
    attrs: { type: 'close' },
    on: { click: () => message.destroy() }
  });

  // 合到一个 VNode 节点中
  const container = h('span', {}, [innerContent, innerIcon]);
  // 最终返回
  return container;
};
// 由于 message 有多种状态，这里需要额外处理
const customMessage = {};
Object.assign(customMessage, message);
// 给 customMessage 循环赋值不同类型的 message函数
['error', 'success', 'info', 'warning', 'warn', 'open'].forEach((type) => {
  customMessage[type] = (content, duration, onClose, icon, key) => {
    // 处理 message[type](config) 参数为对象类型
    // content 提示内容 string| VNode |(h) => VNode
    if (typeof content === 'object') {
      // 内容是 VNode
      return message[type]({ ...content, content: h => createElementVNode(h, content?.content) });
    } else {
      // 内容是 string || 内容是 (h) => VNode
      return message[type](h => createElementVNode(h, content), duration, onClose, icon, key);
    }
  };
});

customMessage.config({ maxCount: 1, duration: 6 });
export default customMessage;
