/*!
 * @author play
 * @date 2019-02-09
 */

import { randomUUID } from "@/utils/util"
import { isNumber, isObject, isArray, isBlank, isString } from "@/utils/dataType"
import message from "@/components/resetAntdv/Message"

// 在数组 arr 的 index 处添加元素 item。不要直接修改数组 arr，结果返回新的数组
export function insertArrItem (arr, item, index) {
  var newArr = arr.concat();
  newArr.splice(index, 0, item);
  return newArr;
}

export function filterAppConfigByType (arr, t) {
  return arr.filter(item => item.appType === t && item.developType === 0)[0];
}

/**
 * 通过 ID 查找当前应用配置
 * @param id {String || Number} 当前菜单下的当前应用配置ID
 * @param configs {Object} 当前菜单的所有应用配置信息
 * @returns {Object} 当前菜单下的当前应用配置信息
 */
export function findAppConfigById (id, configs) {
  let config = {};
  for (let i = 0; i < configs.length; i++) {
    if (configs[i].id === id) {
      config = configs[i];
      break;
    }
  }
  return config;
}

// 枚举文本
export function formatOptionToEnum (o) {
  let e = {};
  for (let i = 0; i < o.length; i++) {
    e[o[i].value] = { label: o[i].label, colorStatus: o[i].colorStatus };
  }
  return e;
}

// tab表格加一行
export function plusTabRow (form, i, rows, dataSource) {
  ++i;
  let fields = form.getFieldValue(rows);
  fields = insertArrItem(fields, { id: randomUUID(), tabCode: "" }, i);
  fields.map((f, n) => { f.tabCode = "tab" + (n + 1) });
  // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
  form.setFieldsValue({ [dataSource]: fields });
  setTimeout(() => { form.setFieldsValue({ [dataSource]: fields }) }, 20)
}

// tab表格减一行
export function minusTabRow (form, id, rows, dataSource) {
  let fields = form.getFieldValue(rows);
  if (fields.length === 1) {
    message.info("至少保留一项");
    return;
  }
  let nextFields = fields.filter((field) => { return field.id !== id; })
  nextFields.map((f, n) => { f.tabCode = "tab" + (n + 1) });

  // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
  form.setFieldsValue({ [dataSource]: nextFields });
  setTimeout(() => { form.setFieldsValue({ [dataSource]: nextFields }) }, 20)
}

// 表格加一行
export function plusRow (form, i, rows, dataSource) {
  ++i;
  let fields = form.getFieldValue(rows);
  fields = insertArrItem(fields, { id: randomUUID() }, i);
  // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
  form.setFieldsValue({ [dataSource]: fields });
  setTimeout(() => { form.setFieldsValue({ [dataSource]: fields }) }, 20)
}

// 表格减一行
export function minusRow (form, id, rows, dataSource) {
  let fields = form.getFieldValue(rows);
  if (fields.length === 1) {
    message.info("至少保留一项");
    return;
  }
  let nextFields = fields.filter((field) => { return field.id !== id; })

  // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
  form.setFieldsValue({ [dataSource]: nextFields });
  setTimeout(() => { form.setFieldsValue({ [dataSource]: nextFields }) }, 20)
}

// 表格行 上移
export function moveRowUp (form, i, rows, dataSource) {
  let fields = form.getFieldValue(rows);
  if (i !== 0) {
    fields[i] = fields.splice(i - 1, 1, fields[i])[0];
    // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
    form.setFieldsValue({ [dataSource]: fields });
    setTimeout(() => { form.setFieldsValue({ [dataSource]: fields }) }, 20)
  }
}

// 表格行 下移
export function moveRowDown (form, i, rows, dataSource) {
  let fields = form.getFieldValue(rows);
  if (i !== fields.length - 1) {
    fields[i] = fields.splice(i + 1, 1, fields[i])[0];
    // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
    form.setFieldsValue({ [dataSource]: fields });
    setTimeout(() => { form.setFieldsValue({ [dataSource]: fields }) }, 20)
  }
}

/**
 * 获取字符串里所有括号"()"里面的变量
 * @param str
 * @returns {Array}
 */
export function getBraceStr (str) {
  let result = [];
  if (!isBlank(str)) {
    let options = str.match(/\((.+?)\)/g);
    if (isArray(options)) {
      options.map(option => {
        result.push(option.substring(1, option.length - 1));
      })
    }
  }
  return result
}

/**
 * 是否携带参数，如果格式为 ?parentId=id
 * @param str
 * @returns {String}
 */
export function getParamStr (str, record) {
  let subParam = {};
  if (isBlank(str)) {
    return subParam;
  }
  if (record && str.indexOf("?") !== -1) {
    let paramStr = str.substring(str.indexOf("?") + 1)
    paramStr.split("&").map(item => {
      let param = item.split("=")
      subParam[param[0]] = record[param[1]]
    })
  }
  if (str.indexOf("?") !== -1) {
    str = str.substring(0, str.indexOf("?"))
  }
  return {
    url: str,
    subParam: subParam
  }
}

/**
 * 把括号"()"之间的字符当做变量，实现动态变量替换URL地址
 * @param str {String} 存在变量对象的字符串
 * @param replacement {Object} 可供替换的对象变量
 * @param isJsonStr {Boolen} 是否是 JSON 字符串
 * @returns {string} 字符串
 */
export function replaceBraceStr (str = "", replacement, isJsonStr = false) {
  getBraceStr(str).map(item => {
    let reg = new RegExp("\\(" + item + "\\)");
    let rep = replacement[item];
    if (rep === undefined) {
      rep = "";
    }
    if (rep === null) {
      rep = "undefined";
    }
    if (isJsonStr) {
      // 字符串 json

      var reg2 = /^[0-9]+.?[0-9]*$/;
      if (reg2.test(rep)) {
        // 数字
        if (isNumber(rep) && (rep > (1e+15))) {
          // 且大于 15 位数（导致失真）
          rep = '"' + rep + '"';
        }
        // 字符串
        const isDoubleQuotes = new RegExp(`"\\(${item}\\)"`, "g").test(str);
        if (isString(rep) && rep.length > 15 && !isDoubleQuotes) {
          // 且大于 15 位数（导致失真）
          rep = '"' + rep + '"';
        }
      }
    }

    str = str.replace(reg, rep);
  });
  return str;
}

// url 参数 转换成 json 对象数据
export function getUrlParamsToObj (url) {
  var hash;
  var myJson = {};
  var hashes = url.slice(url.indexOf("?") + 1).split("&");
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    myJson[hash[0]] = hash[1];
  }
  return myJson;
}

/**
 * 当 this.appConfig.content 数据为字符串类型时 parse 成 json 对象类型
 * @param content {String || Object} this.appConfig.content
 * @returns {Object} 转化后当 this.appConfig.content
 */
export function parseAppConfigContent (content) {
  let appConfigContent = {};
  if (!isBlank(content)) {
    appConfigContent = isString(content) ? JSON.parse(content) : content;
  }
  return appConfigContent;
}

/**
 * JSON字符串转化为JSON对象
 * @param jsonSt {String || Object} JSON 字符串
 * @returns {Object} JSON 对象
 */
export function parseJsonStrToJsonObj (jsonSt) {
  let jsonObj = {};
  if (!isBlank(jsonSt)) {
    if (isObject(jsonSt) || isArray(jsonSt)) {
      jsonSt = JSON.stringify(jsonSt);
    }
    jsonObj = isString(jsonSt) ? JSON.parse(jsonSt) : jsonSt;
  }
  return jsonObj;
}

/**
 * 处理apiPayloads参数格式
 * @param record {Object} 单条记录数据
 * @param keys {Array} 选中的主键id
 * @param selectionRows {Array} 选中的记录
 * @param apiUrl {String} 可供替换的对象变量
 * @param apiPayloads {String} 是否是 JSON 字符串
 * @returns {Object} data 参数对象 message 错误信息 apiUrl 请求地址
 */
export function replaceApiPayloads (subParam, record, apiUrl, apiPayloads, keys, selectionRows) {
  // 切断引用类型，防止被覆盖修改
  record = JSON.parse(JSON.stringify(record));
  keys = keys || [];
  let params = {};
  let message;
  if (!isBlank(record)) { // 单条记录
    Object.assign(record || {}, subParam || {})
    apiUrl = replaceBraceStr(apiUrl, record);
    if (!isBlank(apiPayloads)) {
      params = replaceBraceStr(apiPayloads, record, true);
    }
  } else { // 批量记录
    Object.assign(record || {}, subParam || {})
    apiUrl = replaceBraceStr(apiUrl, record);
    if (isBlank(apiPayloads)) params = keys;
    else params = getParamsByRows(subParam, apiPayloads, selectionRows)
  }
  try {
    if (!isArray(params) && !isBlank(apiPayloads)) {
      let obj = JSON.parse(params);
      if (typeof obj === 'object' && obj) { // 是JSON字符串
        params = obj;
      } else { // 只是普通的文本
        params = params + '';
      }
      // params = JSON.parse(params);
    }
  } catch (error) {
    message = "接口参数格式不正确，必须是严格的json格式；";
  }
  return {
    url: apiUrl,
    data: params,
    message: message
  }
}

/**
 * 处理apiPayloads参数格式[()]数据
 * @param subParam {Object} 携带参数字段
 * @param apiPayloads {String} JSON 字符串
 * @param selectionRows {Array} 选中的记录
 * @returns {params} params 返回参数对象
 */
export function getParamsByRows (subParam, apiPayloads, selectionRows) {
  apiPayloads = apiPayloads.replace(/\s+/g, "");
  selectionRows = selectionRows || [];
  let params = [];
  let result = getBraceStr(apiPayloads);
  if (apiPayloads.indexOf("[(") === 0) {
    selectionRows.map(rows => {
      params.push(rows[result[0]])
    })
  } else {
    result.map(item => {
      // 替换数组
      if (apiPayloads.indexOf("[(" + item + ")]") > 0) {
        let keys = []
        selectionRows.map(rows => {
          keys.push(rows[item])
        })
        apiPayloads = apiPayloads.replace("[(" + item + ")]", JSON.stringify(keys));
      } else { // 替换subParam字段
        // 处理数字过长会丢失精度
        if (!isNaN(subParam[item]) && apiPayloads.indexOf("\"(" + item + ")\"") === -1) {
          apiPayloads = apiPayloads.replace("(" + item + ")", "\"" + subParam[item] + "\"");
        } else {
          apiPayloads = apiPayloads.replace("(" + item + ")", subParam[item]);
        }
      }
    })
    params = apiPayloads
  }
  return params;
}

/**
 * 过滤不符合的数据
 * @param other {String} JSON 字符串
 * @param selectionRows {Array} 选中的记录
 * @returns {params} message 错误信息
 */
export function filterBatchRows (config, selectionRows) {
  let params = {};
  if (!isBlank(config.other) && isString(config.other)) {
    let dataSourceOption = JSON.parse(config.other).dataSourceOption || [];
    for (const i in selectionRows) {
      for (const j in dataSourceOption) {
        let values = dataSourceOption[j].value.split(",")
        let value = selectionRows[i][dataSourceOption[j].label]
        if (values.includes(String(value))) {
          params.message = dataSourceOption[j].prompt
          return params
        }
      }
    }
  }
  return params
}

/**
 * 获取按钮样式
 * @n 按钮样式类型
 * @returns {String} 样式
 */
export function getBtnClassName (n) {
  let c = "";
  switch (n) {
    case 0: // 默认按钮
      c = "ant-btn-primary";
      break;
    case 1: // 边框按钮
      c = "ant-btn-primary ant-btn-background-ghost";
      break;
    case 2: // 红色按钮
      c = "ant-btn-danger";
      break;
    case 3: // 红边按钮
      c = "ant-btn-danger ant-btn-background-ghost";
      break;
    case 4: // 绿色按钮
      c = "ant-btn-success";
      break;
    case 5: // 绿边按钮
      c = "ant-btn-success ant-btn-background-ghost";
      break;
    case 6: // 蓝色按钮
      c = "ant-btn-primary";
      break;
    case 7: // 蓝边按钮
      c = "ant-btn-primary ant-btn-background-ghost";
      break;
    case 8: // 橙色按钮
      c = "ant-btn-warning";
      break;
    case 9: // 橙边按钮
      c = "ant-btn-warning ant-btn-background-ghost";
      break;
    default:
      c = "ant-btn-primary";
  }
  return c;
}

/**
 * 预加工 内部接口 或者 外部接口 http 接口请求
 * @param type {String} 类型：内部接口、外部接口
 * @param method {String} method 是在发出请求时使用的请求方法 get、post、put、delete
 * @param url {String} url 是将用于请求的服务器url
 * @param data {Object} params 是要与请求一起发送的URL参数 或者 `data`是作为请求体发送的数据
 * @returns {Object} 处理结果
 */
export function apiRequestPreproccess (type, method, url, data) {
  if (isBlank(data)) {
    data = null;
  }
  let api = {
    method: "",
    url: "",
    params: null,
    data: null
  }
  if (type === "outer") {
    // 外部接口
    api.method = "post";
    api.url = "/ofa-admin/open-api/thirdParty/call";
    api.data = {
      method,
      url,
      data: data
    };
  } else {
    // 内部接口
    api.method = method;
    api.url = url;
    if (method.toLowerCase()) {

    }
    switch (method.toLowerCase()) {
      case "post":
        api.data = data;
        break;
      case "put":
        api.data = data;
        break;
      case "get":
        api.params = data;
        break;
      case "delete":
        api.data = data;
        break;
      default:
        api.data = data;
    }
  }
  return api;
}

// title 增加一行
export function plusTitleRow (form, i, rows, dataSource) {
  ++i;
  let fields = form.getFieldValue(rows);
  fields = insertArrItem(fields, { id: randomUUID(), titleCode: "" }, i);
  fields.map((f, n) => { f.titleCode = "title" + (n + 1) });
  // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
  form.setFieldsValue({ [dataSource]: fields });
  setTimeout(() => { form.setFieldsValue({ [dataSource]: fields }) }, 20)
}

// title 减一行
export function minusTitleRow (form, id, rows, dataSource) {
  let fields = form.getFieldValue(rows);
  if (fields.length === 1) {
    message.info("至少保留一项");
    return;
  }
  let nextFields = fields.filter((field) => { return field.id !== id; })
  nextFields.map((f, n) => { f.titleCode = "title" + (n + 1) });

  // 先预设值一次，再延迟设值一次，目的是修复 渲染响应的bug
  form.setFieldsValue({ [dataSource]: nextFields });
  setTimeout(() => { form.setFieldsValue({ [dataSource]: nextFields }) }, 20)
}
