
export default ({ request, baseURL }) => ({

// 查询字典数据列表
 listDictData(params) {
    return request({
      url: '/system/dict/data/list',
      method: 'get',
      params
    })
  },

// 查询字典数据详细
getDictData(dictCode) {
    return request({
      url: '/system/dict/data/' + dictCode,
      method: 'get'
    })
  },

// 根据字典类型查询字典数据信息
getDicts(dictType) {
    return request({
      url: '/system/dict/data/type/' + dictType,
      method: 'get'
    })
  },

// 新增字典数据
  addDictData(data) {
    return request({
      url: '/system/dict/data',
      method: 'post',
      data
    })
  },

// 修改字典数据
 updateDictData(data) {
    return request({
      url: '/system/dict/data',
      method: 'put',
      data
    })
  },

// 删除字典数据
  delDictData(dictCode) {
    return request({
      url: '/system/dict/data/' + dictCode,
      method: 'delete'
    })
  }

})
