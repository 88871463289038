/*!
 * @file api/org.js
 * @desc 租户管理
 */
export default ({ request, baseURL }) => ({

  // 获取用户所属的租户列表
  queryUserBelongOrgs () {
    return request({
      url: `${baseURL}/ofa-admin/admin/ofaUser/org/ref`,
      method: "get"
    })
  },

  // 设置用户默认租户及平台层级
  setBelongOrgUserInf (id, platform) {
    return request({
      url: `${baseURL}/system/user/changeinfo?tenantId=${id}&platform=${platform}`,
      method: "get"
    })
  },

  // 获取用户当前消息数
  queryUserMessageCount () {
    return request({
      url: `${baseURL}/ofa-admin/admin/ofaMessage/unread`,
      method: "get"
    })
  },

  // 获取租户列表
  queryOrgList (params) {
    return request({
      url: `${baseURL}/ofa-admin/admin/ofaOrganization/org/list`,
      method: "get",
      params
    })
  },
  // 获取租户列表及用户相关内容
  queryTenantAddUser (params) {
    return request({
      url: `${baseURL}/system/user/getTypeInfo`,
      method: "get",
      params
    })
  },

  // 获取用户当前消息数
  getUnreadList () {
    return request({
      url: `${baseURL}/ofa-admin/admin/ofaMessage/unreadList`,
      method: "get"
    })
  },

  // 标为已读
  setNewReaded (data) {
    return request({
      url: `${baseURL}/ofa-admin/admin/ofaMessage/read`,
      method: "put",
      data
    })
  }

})
