let vpci = {
  tabRouteName: [], // // tab路由name列表 ["myHome", "m389185613833064448", "m418825960496406528"]
  cache: {}, // 缓存的对象，e.g. {/system/menu/list: VNode, /develop/app: VNode}
  keys: [], // 缓存的数组， e.g. ["/develop/app", "/system/menu/list"]
  nameKey: {} // {m389185613833064448: "/system/menu/list", m418825960496406528: "/develop/app"}
}
const globalMain = {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const $vnode = vm.$vnode;
      if ($vnode?.data?.keepAlive && $vnode?.parent?.componentInstance?.cache && ($vnode?.key || $vnode?.componentOptions)) {
        let key = $vnode.key === null ? $vnode.componentOptions.Ctor.cid + ($vnode.componentOptions.tag ? `::${$vnode.componentOptions.tag}` : "") : $vnode.key;
        let cache = $vnode.parent.componentInstance.cache;
        let keys = $vnode.parent.componentInstance.keys;
        vpci.cache = cache;
        vpci.keys = keys;
        vpci.nameKey[to.name] = key;
      }
      vm.handleCloseTabPage()
    })
  },
  methods: {
    handleCloseTabPage () {
      const tabPages = this.$store.state.permission.tabPages
      const temp = [...vpci.tabRouteName];
      const newpages = tabPages.map(item => {
        return item.name;
      })
      if (newpages.join(",") !== temp.join(",")) { // 判断tab是否有变化，有变化进行下一步
        vpci.tabRouteName = [...newpages]; // 保存新的tab数组
        // 判断是否有页面被删除了
        temp.forEach(item => {
          if (newpages.indexOf(item) < 0) { // 监听到这个标签页被关闭了
            // 删除缓存的核心方法 start
            const key = vpci.nameKey[item];
            if (key && vpci.cache[key]) {
              if (vpci.keys.length && vpci.keys.indexOf(key) > -1) {
                vpci.keys.splice(vpci.keys.indexOf(key), 1);
              }
              vpci.cache[key].componentInstance.$destroy(); // 完全销毁一个实例
              delete vpci.cache[key];
              delete vpci.nameKey[item];
            }
            // 删除缓存的核心方法 end
          }
        })
      }
    }
  }
};

export default globalMain;
