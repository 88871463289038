/**
 * 获取数据类型
 * @param data
 * @returns {String}
 */
export function dataType (data) {
  const type = {
    "[object String]": "string",
    "[object Array]": "array",
    "[object Object]": "object",
    "[object Null]": "null",
    "[object Number]": "number",
    "[object Undefined]": "undefined",
    "[object Boolean]": "boolean",
    "[object Function]": "function"
  }
  const resType = Object.prototype.toString.call(data)
  return type[resType]
}

/**
 * 判断是否是字符串类型
 * @param data
 * @returns {Boolean}
 */
export function isString (data) {
  return dataType(data) === "string"
}

/**
 * 判断是否是数组类型
 * @param data
 * @returns {Boolean}
 */
export function isArray (data) {
  return dataType(data) === "array"
}

/**
 * 判断是否是对象类型
 * @param data
 * @returns {Boolean}
 */
export function isObject (data) {
  return dataType(data) === "object"
}

/**
 * 判断是否是null类型
 * @param data
 * @returns {Boolean}
 */
export function isNull (data) {
  return dataType(data) === "null"
}

/**
 * 判断是否是数字类型
 * @param data
 * @returns {Boolean}
 */
export function isNumber (data) {
  return dataType(data) === "number"
}

/**
 * 判断是否是Undefined类型
 * @param data
 * @returns {Boolean}
 */
export function isUndefined (data) {
  return dataType(data) === "undefined"
}

/**
 * 判断是否是布尔类型
 * @param data
 * @returns {Boolean}
 */
export function isBoolean (data) {
  return dataType(data) === "boolean"
}

/**
 * 判断是否是函数类型
 * @param data
 * @returns {Boolean}
 */
export function isFunction (data) {
  return dataType(data) === "function"
}

/**
 * 判断是否是空对象
 * @param data
 * @returns {Boolean}
 */
export function isEmptyObject (data) {
  let b = false;
  if (isObject(data) && JSON.stringify(data) === "{}") {
    b = true;
  }
  return b
}

/**
 * 判断是否是 "" 或 undefined 或 null 或 {} 或 []
 * @param data
 * @returns {Boolean}
 */
export function isBlank (t) {
  return t === "" || t === undefined || isNull(t) || (isObject(t) && JSON.stringify(t) === "{}") || (isArray(t) && t.length < 1);
}

/**
 * 判断是否是json字符串
 * @param {string}  str  -  字符串
 */
 export function isJSON (str) {
  if (typeof str === "string") {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
}
