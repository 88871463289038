<template>
  <section class="query-param animated fadeIn" :class="{ 'hidden': isHidden }">
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    isHidden: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.query-param {
  position: relative;
  padding-bottom: 16px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: $pure-white;
  border-radius: 4px;
  z-index: 10;
  &::after {
    content: "";
    width: 100%;
    height: 16px;
    position: absolute;
    left: 0;
    bottom: -16px;
    background: #f0f2f5;
  }
  ::v-deep {
    .ant-form {
      .ant-form-item-label,
      .ant-form-item-control {
        line-height: 20px;
      }
      .ant-form-item {
        margin-bottom: 12px!important;
        padding-bottom: 0;
      }
    }
    .for-btn {
      margin-bottom: 24px;
      &.for-btn-config {
        margin-bottom: 0;
        margin-top: 24px;
      }
      .ant-btn {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .for-btn.for-btn-pt16 {
      margin: 8px 0;
    }
  }
}
</style>
