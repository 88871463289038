/*!
 * @file src/utils/async.js
 * @desc 异步流程控制
 */

/**
 * 加载异步组件，用于 Vue.js 动态组件赋值(:is="")
 * @param path {String} vue单文件组件路径
 * @returns {Promise} vue单文件组Promise
 */
export function asyncComponent (path) {
  let componentPath = path.split(".vue")[0];
  if (componentPath.indexOf("/") !== 0) {
    componentPath = "/" + componentPath;
  }
  // `@/views${componentPath}.vue` 这种形式才是字符串静态
  return resolve => require([`@/views${componentPath}.vue`], resolve);
}

/**
 * 加载异步组件，用于菜单应用 Vue.js 动态组件赋值(:is="")
 * @param path {String} vue单文件组件路径
 * @returns {Promise} vue单文件组Promise
 */
export function asyncAppComponent (path) {
  let componentPath = path.split(".vue")[0];
  return componentPath;
}
