import Vue from "vue"
import App from "./App.vue"
import router from "@/router"
import store from "@/store"
import init from "@/core/init"
import i18n from "@/locales"
import "@/directive/permission.js"
import "@/core/use"
import globalMain from "@/mixins/globalMain.js"

import "@/assets/css/theme.css"
import "@/assets/scss/main.scss"
import "@/router/permission"
import "@/utils/filter"
import VConsole from 'vconsole'
// 字典标签组件
import DictTag from '@/components/DictTag'
// 字典数据组件
import DictData from '@/components/DictData'
// 全局组件挂载
Vue.component('DictTag', DictTag)

Vue.config.productionTip = false;
Vue.mixin(globalMain);

DictData.install()
// 创建和挂载根实例
new Vue({
  router,
  store,
  i18n,
  created: init,
  render: h => h(App)
}).$mount("#app")

// if (process.env.VUE_APP_MODE !== 'production') {
//   // 开发环境逻辑
//   Vue.use(new VConsole())
// }
