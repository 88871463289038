<template>
<div>
  <!--
    给editor加key是因为给tinymce keep-alive以后组件切换时tinymce编辑器会显示异常，
    在activated钩子里改变key的值可以让编辑器重新创建
  -->
  <editor :init="tinymceInit" v-model="content" :key="tinymceFlag" :disabled="disabled" @onClick="onClick" @onChange="onChange"></editor>
  <a-spin :spinning="isSpinning" />
</div>
</template>
<script>
import tinymce from "tinymce/tinymce"
import "tinymce/themes/silver/theme"
import Editor from "@tinymce/tinymce-vue"

import "tinymce/plugins/textcolor"
import "tinymce/plugins/advlist"
import "tinymce/plugins/table"
import "tinymce/plugins/lists"
import "tinymce/plugins/paste"
import "tinymce/plugins/preview"
import "tinymce/plugins/fullscreen"
import "./image/plugin.js"
import "./media/plugin.js"
import "tinymce/plugins/code"
import "tinymce/plugins/link"
import "tinymce/plugins/hr"
import "tinymce/plugins/pagebreak"
import "tinymce/plugins/codesample"
import "tinymce/plugins/charmap"
import "tinymce/plugins/insertdatetime"
import "tinymce/plugins/wordcount"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/visualblocks"
import "tinymce/plugins/toc"
import "tinymce/plugins/tabfocus"
import "tinymce/plugins/anchor"
import "@npkg/tinymce-plugins/importword"
import "@npkg/tinymce-plugins/letterspacing"
import "@npkg/tinymce-plugins/imagetools"
import "@npkg/tinymce-plugins/layout"

import api from "@/api"
import { isBlank } from "@/utils"
import { randomUUID } from "@/utils/util"

const SIZE = 1024 * 1024 * 15; // 切片大小，使用切片的最小值。
const Status = {
  wait: "wait",
  pause: "pause",
  uploading: "uploading"
};

export default {
  name: "TinymceEditor",
  components: {
    "editor": Editor
  },
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    // 传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: ""
    },
    config: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: "paste preview fullscreen image imagetools link media code table advlist lists indent2em importword letterspacing layout hr pagebreak codesample charmap insertdatetime wordcount searchreplace visualblocks toc tabfocus anchor"
    },
    toolbar: {
      type: [String, Array],
      default: "code formatselect fontselect fontsizeselect forecolor backcolor bold italic underline strikethrough link alignleft aligncenter alignright alignjustify indent2em outdent indent lineheight letterspacing bullist numlist blockquote subscript superscript typesetting layout removeformat table image media importword charmap hr pagebreak anchor codesample visualblocks insertdatetime toc cut copy paste pastetext undo redo searchreplace wordcount preview fullscreen"
      // default: "importword | typesetting  | code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent | styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media charmap hr pagebreak insertdatetime preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs"
    },
    menubar: {
      type: [String, Boolean],
      default: false
    },
    imageRemarks: {
      type: Boolean,
      default: true
    },
    mediaRemarks: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tinymceFlag: 1,
      tinymceInit: Object.assign({
        skin_url: process.env.VUE_APP_ROOTFOLDER + "/tinymce/skins/ui/oxide",
        language_url: process.env.VUE_APP_ROOTFOLDER + "/tinymce/langs/zh_CN.js",
        font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;楷书=KaiTi,serif;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats",
        fontsize_formats: "10px 12px 14px 16px 18px 21px 24px 36px 48px 56px 72px",
        language: "zh_CN",
        height: 260,
        contextmenu: "copy paste link",
        browser_spellcheck: true, // 拼写检查
        branding: false, // 去水印
        elementpath: false, // 禁用编辑器底部的状态栏
        statusbar: false, // 隐藏编辑器底部的状态栏
        paste_data_images: true, // 允许粘贴图像
        menubar: this.menubar, // 配置/隐藏 最上方menu
        plugins: this.plugins,
        toolbar: this.toolbar,
        toolbar_sticky: true,
        importcss_append: true,
        powerpaste_word_import: 'propmt', // 参数可以是propmt, merge, clear，效果自行切换对比
        powerpaste_html_import: 'propmt', // propmt, merge, clear
        powerpaste_allow_local_images: true,
        // 添加扩展插件
        external_plugins: {
          powerpaste: '/tinymce/powerpaste/plugin.min.js'
        },
        // 上传本地图片
        images_upload_handler: (blobInfo, success, failure, progress) => {
          this.imagesUploadHandler(blobInfo, success, failure, progress)
        },

        // 想要哪一个图标提供本地文件选择功能，参数可为media(媒体)、image(图片)、file(文件)，多个参数用空格分隔
        file_picker_types: "media file",
        file_picker_callback: (cb, value, meta) => {
          // 当点击meidia图标上传时,判断meta.filetype == 'media'有必要，因为file_picker_callback是media(媒体)、image(图片)、file(文件)的共同入口
          this.uploadCb = cb;
          if (meta.filetype === "media") {
            this.mediaUploadHandler(cb, value, meta);
          } else if (meta.filetype === "file") {
            this.fileUploadHandler(cb, value, meta);
          }
        },
        paste_postprocess: (plugin, args) => {
          // args.node可以获取到粘贴过来的所有dom节点，直接可以用操作dom的方式取修改它
          // 注意此函数不需要return返回值，直接修改即可
          if (this.imageRemarks) {
            const childNodes = Array.from(args.node.childNodes)
            childNodes.forEach(item => {
              if (item.tagName === 'IMG') {
                item.style.display = 'block'
                item.style.margin = '0 auto'
                item.style.maxWidth = '75%'
                const remarks = document.createElement("p");
                const brank = document.createElement("br");
                remarks.classList.add('remarks');
                remarks.innerHTML = "文章插图";
                remarks.style.textAlign = 'center';
                remarks.style.marginBottom = '10px';
                args.node.appendChild(remarks);
                args.node.appendChild(brank);
              }
            })
          }
        },
        init_instance_callback: (editor) => {
          if (editor.container && editor.container.querySelector('button[aria-label="粘贴为文本"]')) {
            editor.container.querySelector('button[aria-label="粘贴为文本"]').style.display = 'none'
          }
        },
        setup: (editor) => {
          editor.ui.registry.addButton('typesetting', {
            // text: '自动排版',
            icon: 'select-all',
            tooltip: '自动排版 - 选中需要排版的文字，点击此按钮即可自动排版',
            onAction: async () => {
              let editorSelectVal = editor.selection.getContent();
              if (isBlank(editorSelectVal)) {
                await this.confirm()
                let editorVal = editor.getContent();
                editor.setContent(this.typesettingText(editorVal));
                return
              }

              if (/<[^/>]+>/.test(editorSelectVal)) {
                editorSelectVal = this.typesettingText(editorSelectVal)
              } else {
                if (/<img/g.test(editorSelectVal)) {
                  editorSelectVal = editorSelectVal.replace(/<img/g, `<img style="${this.editorStyle.img}"`)
                }
                editorSelectVal = `<span style="${this.editorStyle.text}">${editorSelectVal}<span>`
              }
              editor.selection.setContent(editorSelectVal);
            }
          });
        },
        media_remarks: this.mediaRemarks ? `<p class="remarks" style="text-align: center;margin-bottom: 10px;">文章多媒体</p><br/>` : undefined,
        image_remarks: this.imageRemarks ? `<p class="remarks" style="text-align: center;margin-bottom: 10px;">文章插图</p><br/>` : undefined,
        // content_style: `video,.mce-object-video {display:block;margin: 0 auto;max-width:75%}}`
        // 预处理函数
        importword_handler: function (editor, files, next) {
          if (files[0].name.substr(files[0].name.lastIndexOf(".") + 1) === 'docx') {
            editor.notificationManager.open({
              text: '正在转换中...',
              type: 'info',
              closeButton: false
            });
            next(files);
          } else {
            editor.notificationManager.open({
              text: '目前仅支持docx文件格式，若为doc，请将扩展名改为docx',
              type: 'warning'
            })
          }
        },
        // 过滤函数
        importword_filter: function (result, insert, message) {
        // 自定义操作部分
          insert(result) // 回插函数
        }
      }, this.config),
      content: "",
      container: {
        file: null,
        hash: "",
        worker: null
      },
      hashPercentage: 0, // md5
      ossPath: process.env.VUE_APP_OSS_DOMAIN,
      data: [],
      canCancel: false, // 防止多次取消上传接口报错
      status: Status.wait,
      Status,
      fakeUploadPercentage: 0,
      loadingBox: null, // loading动画DOM
      uploadCb: null, // 附件上传和媒体资源上传回调事件
      isSpinning: false,
      editorStyle: {
        text: 'font-size:16px;font-family:Microsoft YaHei;text-indent: 2em;line-height: 2;',
        img: 'display:block;margin: 0 auto;max-width:75%;',
        remarks: 'text-indent:0;text-align: center;margin-bottom: 10px;'
      }
    }
  },
  computed: {
    // 上传进度
    uploadPercentage () {
      if (!this.container.file || !this.data.length) return 0;
      const loaded = this.data
        .map(item => item.size * item.percentage)
        .reduce((acc, cur) => acc + cur);
      return parseInt((loaded / this.container.file.size).toFixed(2));
    }
  },
  methods: {
    typesettingText (editorVal) {
      editorVal = editorVal.replace(/style="[^=>]*"/g, "")
      editorVal = editorVal.replace(/<strong\s*>|<\/strong>/g, "")
      editorVal = editorVal.replace(/<em\s*>|<\/em>/g, "")
      editorVal = editorVal.replace(/<[^/>class="remarks"]+>/g, (el) => {
        if (/\s/.test(el)) {
          return el.replace(/\s/, ` style="${this.editorStyle.text}" `)
        } else {
          return el.replace('>', ` style="${this.editorStyle.text}">`)
        }
      })
      if (/class="remarks"/g.test(editorVal)) {
        editorVal = editorVal.replace(/class="remarks"/g, `class="remarks" style="${this.editorStyle.remarks}"`)
      }
      if (/<img/g.test(editorVal)) {
        editorVal = editorVal.replace(/<img/g, `<img style="${this.editorStyle.img}" `)
      }
      if (/<video/g.test(editorVal)) {
        editorVal = editorVal.replace(/<video/g, `<video style="${this.editorStyle.img}" `)
      }
      return editorVal
    },
    confirm () {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '是否自动排版全部内容',
          onOk () {
            resolve()
          },
          onCancel () {
          }
        });
      })
    },
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick (e) {
      this.$emit("onClick", e, tinymce)
    },

    // 自定义或第三方的表单控件，也可以与 Form 组件一起使用。只要该组件遵循以下的约定：
    // 提供受控属性 value 或其它与 valuePropName-参数) 的值同名的属性。
    // 提供 onChange 事件或 trigger-参数) 的值同名的事件。
    // 不能是函数式组件。
    onChange () {
      // Should provide an event to pass value to Form.
      this.$emit("change", this.content);
    },

    // 图片上传
    imagesUploadHandler (blobInfo, success, failure, progress) {
      progress(0);
      const formData = new FormData();
      const blobObj = blobInfo.blob();
      // 有name为file对象，走工具栏的上传方式。没有则是blob对象，走复制方式。
      const isCopy = !blobObj.name;
      if (!isCopy) {
        formData.append("file", blobObj);
      } else {
        this.isSpinning = true;
        formData.append("file", blobObj, `${randomUUID()}.${blobObj.type.split("/")[1]}`);
      }

      api.uploadFile(formData, formData.get("file").name, this.config?.fileUrl).then(
        res => {
          if (res.code === 200) {
            this.$message.success("上传成功!")
            success(res.data.indexOf("http") !== -1 ? res.data : process.env.VUE_APP_OSS_DOMAIN + res.data);
          } else {
            failure("上传失败")
          }
          if (this.isSpinning) this.isSpinning = false;
        }
      ).finally(() => {
        progress(100);
      }).catch(() => {
        failure("上传出错")
        if (this.isSpinning) this.isSpinning = false;
      })
    },

    // 附件上传
    fileUploadHandler (cb, value, meta) {
      if (this.status === "uploading") return
      // 文件分类
      let filetype = ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4";
      // 创建一个隐藏的type=file的文件选择input
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", filetype);
      // 判断是否已经创建loading动画的DOM结构
      if (!this.loadingBox) {
        let loadingBox = document.createElement("div");
        loadingBox.classList.add("tox-dialog__busy-spinner");
        loadingBox.innerHTML = "<div class='tox-spinner'><div></div><div></div><div></div></div>";
        this.loadingBox = loadingBox;
      }
      // 监听input[type="file"]选择文件
      input.onchange = e => {
        let file = e.currentTarget.files[0];
        this.container.file = file;
        // 判断上传文件是否大于切片大小
        if (file.size > SIZE) {
          this.handleFileChunk();
        } else {
          // tinymce.activeEditor.setProgressState(true);
          const formData = new FormData();
          formData.append("file", file);
          this.status = Status.uploading;
          document.querySelector(".tox-dialog").appendChild(this.loadingBox);
          api.uploadFile(formData, formData.get("file").name, this.config?.fileUrl).then(
            res => {
              if (res.code === 200) {
                let r = res.data.indexOf("http") !== -1 ? res.data : process.env.VUE_APP_OSS_DOMAIN + res.data;
                cb(r, { text: "" });
              }
            }
          ).finally(() => {
            this.status = Status.wait;
            this.loadingBox.remove();
            // tinymce.activeEditor.setProgressState(false);
          });
        }
      }
      // 触发点击
      input.click();
    },

    // 视频上传
    mediaUploadHandler (cb, value, meta) {
      // 创建一个隐藏的type=file的文件选择input
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "audio/mp3, audio/mp4, video/mp4, application/ogg, audio/ogg");
      // 判断是否已经创建loading动画的DOM结构
      if (!this.loadingBox) {
        let loadingBox = document.createElement("div");
        loadingBox.classList.add("tox-dialog__busy-spinner");
        loadingBox.innerHTML = "<div class='tox-spinner'><div></div><div></div><div></div></div>";
        this.loadingBox = loadingBox;
      }
      input.onchange = e => {
        let file = e.currentTarget.files[0];
        let fileSuffix = file.name.split(".").pop();
        if (fileSuffix !== "mp4" && fileSuffix !== "mp3" && fileSuffix !== "ogg" && fileSuffix !== "webm") {
          alert("支持上传.mp4、.mp3、.ogg、webm格式的文件");
          return;
        }
        this.container.file = file;
        if (file.size > SIZE) {
          this.handleFileChunk();
        } else {
          // tinymce.activeEditor.setProgressState(true);
          const formData = new FormData();
          formData.append("file", file);
          this.status = Status.uploading;
          document.querySelector(".tox-dialog").appendChild(this.loadingBox);
          api.uploadFile(formData, formData.get("file").name, this.config?.fileUrl).then(
            res => {
              if (res.code === 200) {
                let r = res.data.indexOf("http") !== -1 ? res.data : process.env.VUE_APP_OSS_DOMAIN + res.data;
                cb(r);
              }
            }
          ).finally(() => {
            // tinymce.activeEditor.setProgressState(false);
            this.status = Status.wait;
            this.loadingBox.remove();
          });
        }
      }
      // 触发点击
      input.click();
    },
    // 修改 tinymceFlag
    changeTinymceFlag () {
      this.tinymceFlag++
    },
    // 文件切片相关操作
    async handleFileChunk () {
      if (!this.container.file) return
      // tinymce.activeEditor.setProgressState(true);
      this.status = Status.uploading;
      document.querySelector(".tox-dialog").appendChild(this.loadingBox);
      const fileChunkList = this.createFileChunk(this.container.file); // 生成文件切片
      this.container.hash = await this.calculateHash(fileChunkList); // 生成文件唯一的hash标识
      const { shouldUpload } = await this.verifyUpload(this.container.hash); // 根据hash判断是否以上传过
      if (!shouldUpload) {
        // tinymce.activeEditor.setProgressState(false);
        this.uploadCb(this.container.file.url);
        this.status = Status.wait;
        this.$message.success("上传成功");
        this.loadingBox.remove();
        return;
      }
      this.data = fileChunkList.map(({ file }, index) => ({
        fileHash: this.container.hash,
        index,
        hash: this.container.hash + "-" + index,
        chunk: file,
        size: file.size,
        percentage: 0 // uploadedList.includes(index) ? 100 : 0
      }));
      this.uploadChunks();
    },
    // 生成文件切片
    createFileChunk (file, size = 1024 * 1024 * 5) {
      const fileChunkList = [];
      let cur = 0;
      while (cur < file.size) {
        fileChunkList.push({ file: file.slice(cur, cur + size) });
        cur += size;
      }
      return fileChunkList;
    },
    // 生成文件 hash（web-worker）
    calculateHash (fileChunkList) {
      return new Promise(resolve => {
        this.container.worker = new Worker(process.env.VUE_APP_ROOTFOLDER + "/static/js/hash.js");
        this.container.worker.postMessage({ fileChunkList });
        this.container.worker.onmessage = e => {
          const { percentage, hash } = e.data;
          this.hashPercentage = percentage;
          if (hash) {
            resolve(hash);
          }
        };
      });
    },
    // 根据 hash 验证文件是否曾经已经被上传过
    // 没有才进行上传
    async verifyUpload (fileHash) {
      const res = await this.$axios.get(`/tcc-file/file/multipart/fileCheck/${fileHash}`);
      let d = {
        shouldUpload: false
      };
      if (res.code === 200) {
        d.shouldUpload = isBlank(res.data);
        this.container.file.url = this.ossPath + res.data;
        if (!d.shouldUpload) {
          // this.triggerChange(res.data);
        }
      }
      return d;
    },
    // 自定义表单控件触发 change 实现双向绑定
    triggerChange (v) {
      this.$emit("change", v);
    },
    // 上传切片，同时过滤已上传的切片
    async uploadChunks (uploadedList = []) {
      let res = {};
      for (let i = 0; i < this.data.length; i++) {
        let { chunk, index } = this.data[i];
        const formData = new FormData();
        formData.append("file", chunk); // 分片文件
        formData.append("fileHash", this.container.hash); // 文件hash值
        formData.append("fileName", this.container.file.name); // 文件名
        formData.append("partNumber", index + 1); // 分片号，范围1-10000
        res = await this.postChunk(formData, index);
        if (res.code !== 200) {
          // tinymce.activeEditor.setProgressState(false);
          this.$message.error(res.msg);
          this.handleCancel(); // 取消上传
          this.status = Status.wait;
          this.loadingBox.remove();
          break;
        }
        if (i >= this.data.length - 1) {
          if (res.code === 200) {
            this.mergeRequest();
          }
        }
      }
    },
    // 上传分片
    async postChunk (formData, index) {
      return this.$axios.post(
        "/tcc-file/file/multipart/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          timeout: 120000,
          onUploadProgress: progressEvent => {
            this.createProgressHandler(this.data[index], progressEvent)
          }
        }
      )
    },
    // 取消上传
    async handleCancel () {
      this.canCancel = true;
      if (this.status === Status.uploading) {
        this.deletChunks(this.container.hash);
      }
    },
    // 删除已上传的切片
    async deletChunks (fileHash) {
      return this.$axios.delete(
        `/tcc-file/file/multipart/${fileHash}`
      ).finally(() => {
        this.resetData();
        this.status = Status.wait;
        this.canCancel = false;
      })
    },
    // 通知服务端合并切片
    async mergeRequest () {
      let partTotalNum = this.data.length;
      let fileHash = this.container.hash; // 文件hash值
      this.$axios.get(
        `/tcc-file/file/multipart/merge?fileHash=${fileHash}&partTotalNum=${partTotalNum}`
      ).then(res => {
        if (res.code === 200) {
          // this.triggerChange(res.data);
          // this.container.file.url = this.ossPath + res.data;
          // this.uploadCb(this.container.file.url, { text: "" });
          let r = res.data.indexOf("http") !== -1 ? res.data : process.env.VUE_APP_OSS_DOMAIN + res.data;
          this.uploadCb(r);
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
          this.handleCancel(); // 取消上传
        }
      }).finally(() => {
        // tinymce.activeEditor.setProgressState(false);
        this.status = Status.wait;
        this.loadingBox.remove();
      })
    },
    // 保存每个 chunk 的进度数据
    createProgressHandler (item, e) {
      item.percentage = parseInt(String((e.loaded / e.total) * 100));
    },
    // 重置 data
    resetData () {
      this.container.file = null;
      this.container.hash = "";
      if (this.container.worker) {
        this.container.worker.onmessage = null;
      }
    }
  },
  activated () {
    this.tinymceFlag++
  },
  watch: {
    value (v) {
      this.content = v;
    },
    content (h) {
      this.$emit("input", h)
      tinymce.activeEditor.uploadImages();
    },
    // 更新总进度条
    uploadPercentage (now) {
      if (now > this.fakeUploadPercentage) {
        this.fakeUploadPercentage = now;
      }
    }
  },
  mounted () {
    this.content = this.value;
  }
}

</script>

<style scoped lang="scss">
::v-deep {
  .ant-spin-spinning {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 99;
  }
}
</style>
