<template>
  <div class="chunk-file-upload">
    <a-upload
      :default-file-list="defaultFileList"
      :file-list="fileList"
      :disabled="disabled || status !== Status.wait"
      :remove="handleRemove"
      :multiple="false"
      :before-upload="beforeUpload"
      :accept="accept"
      @change="handleChange"
    >
      <a-button :disabled="disabled || status !== Status.wait">
        <a-icon :type="status === Status.uploading ? 'loading' : 'upload'" />
        {{placeholder}}
      </a-button>
    </a-upload>
    <div class="operating-button">
      <a-button @click="handleCancel" :disabled="canCancel || status !== Status.uploading" v-if="status === Status.uploading">取消</a-button>
    </div>
    <div class="tip" v-if="message.length">{{ message }}</div>
    <a-progress :percent="fakeUploadPercentage" v-if="status === Status.uploading"></a-progress>
  </div>
</template>

<script>
import { isBlank } from "@/utils";
import { randomUUID } from "@/utils/util"
const SIZE = 5 * 1024 * 1024; // 切片大小
const Status = {
  wait: "wait",
  pause: "pause",
  uploading: "uploading"
};
export default {
  name: "chunkFileUpload",
  props: {
    // 自定义表单控件绑定 value 实现双向绑定
    value: {
      type: String
    },
    value1: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请选择文件"
    },
    accept: {
      type: String,
      default: "" // "application/*,audio/*,image/*,text/*,video/*,.doc"
    },
    limitSize: {
      type: Number,
      default: 1024 * 1024 * 1024
    },
    message: {
      type: String,
      default: "只能上传指定格式的文件，且大小不超过指定大小。"
    }
  },
  filters: {
    transformByte (val) {
      return Number((val / 1024).toFixed(0));
    }
  },
  data: () => ({
    ossPath: process.env.VUE_APP_OSS_DOMAIN,
    defaultFileList: [], // 默认已经上传的文件列表
    fileList: null, // 上传文件列表
    Status,
    container: {
      file: null,
      hash: "",
      worker: null
    },
    hashPercentage: 0, // md5
    data: [],
    requestList: [],
    status: Status.wait,
    // 当暂停时会取消 xhr 导致进度条后退
    // 为了避免这种情况，需要定义一个假的进度条
    fakeUploadPercentage: 0,
    canCancel: false // 防止多次取消上传接口报错
  }),
  computed: {
    // 上传进度
    uploadPercentage () {
      if (!this.container.file || !this.data.length) return 0;
      const loaded = this.data
        .map(item => item.size * item.percentage)
        .reduce((acc, cur) => acc + cur);
      return parseInt((loaded / this.container.file.size).toFixed(2));
    }
  },
  watch: {
    // 更新总进度条
    uploadPercentage (now) {
      if (now > this.fakeUploadPercentage) {
        this.fakeUploadPercentage = now;
      }
    },
    value () {
      this.initFile();
    }
  },
  methods: {
    // 初始文件
    initFile () {
      let fileName = this.value;
      if (fileName) {
        this.defaultFileList.push(
          {
            uid: randomUUID(),
            name: fileName,
            url: this.ossPath + fileName
          }
        );
      } else if (fileName !== undefined) {
        this.fileList = [];
      }
    },

    // 自定义表单控件触发 change 实现双向绑定
    triggerChange (v) {
      this.$emit("change", v);
    },

    // 修改上传文件
    handleChange ({ file, fileList }) {
      if (file.size > this.limitSize) {
        this.$message.error("上传文件超出限制大小!");
      } else {
        if (!isBlank(fileList)) {
          this.container.file = file;
          this.handleUpload();
        }
      }
    },

    // 移除上传文件
    handleRemove (file) {
      this.container.file = null;
      this.fileList = [];
      this.triggerChange(null);
    },

    // 上传文件之前的钩子
    beforeUpload () {
      return false;
    },

    // 重置 data
    resetData () {
      this.container.file = null;
      this.container.hash = "";
      if (this.container.worker) {
        this.container.worker.onmessage = null;
      }
      this.fileList = null;
    },

    // 取消上传
    async handleCancel () {
      this.canCancel = true;
      if (this.status === Status.uploading) {
        this.deletChunks(this.container.hash);
      }
    },

    // 生成文件切片
    createFileChunk (file, size = SIZE) {
      const fileChunkList = [];
      let cur = 0;
      while (cur < file.size) {
        fileChunkList.push({ file: file.slice(cur, cur + size) });
        cur += size;
      }
      return fileChunkList;
    },

    // 生成文件 hash（web-worker）
    calculateHash (fileChunkList) {
      return new Promise(resolve => {
        this.container.worker = new Worker(process.env.VUE_APP_ROOTFOLDER + "/static/js/hash.js");
        this.container.worker.postMessage({ fileChunkList });
        this.container.worker.onmessage = e => {
          const { percentage, hash } = e.data;
          this.hashPercentage = percentage;
          if (hash) {
            resolve(hash);
          }
        };
      });
    },

    // 上传
    async handleUpload () {
      if (!this.container.file) return;
      this.status = Status.uploading;
      const fileChunkList = this.createFileChunk(this.container.file);
      this.container.hash = await this.calculateHash(fileChunkList);
      const { shouldUpload } = await this.verifyUpload(this.container.hash);
      if (!shouldUpload) {
        this.fileList = [this.container.file];
        // let type = this.getFileFormat();
        // if ((type.indexOf("audio") !== -1) || (type.indexOf("video") !== -1)) {
        //   this.getAudioDuration();
        // }
        this.$message.success("上传成功");
        this.status = Status.wait;
        return;
      }
      this.data = fileChunkList.map(({ file }, index) => ({
        fileHash: this.container.hash,
        index,
        hash: this.container.hash + "-" + index,
        chunk: file,
        size: file.size,
        percentage: 0 // uploadedList.includes(index) ? 100 : 0
      }));
      this.uploadChunks();
    },

    // 上传分片
    async postChunk (formData, index) {
      return this.$axios.post(
        "/tcc-file/file/multipart/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          timeout: 120000,
          onUploadProgress: progressEvent => {
            this.createProgressHandler(this.data[index], progressEvent)
          }
        }
      )
    },

    // 删除已上传的切片
    async deletChunks (fileHash) {
      return this.$axios.delete(
        `/tcc-file/file/multipart/${fileHash}`
      ).finally(() => {
        this.resetData();
        this.status = Status.wait;
        this.canCancel = false;
      })
    },

    // 上传切片，同时过滤已上传的切片
    async uploadChunks (uploadedList = []) {
      let res = {};
      for (let i = 0; i < this.data.length; i++) {
        let { chunk, index } = this.data[i];
        const formData = new FormData();
        formData.append("file", chunk); // 分片文件
        formData.append("fileHash", this.container.hash); // 文件hash值
        formData.append("fileName", this.container.file.name); // 文件名
        formData.append("partNumber", index + 1); // 分片号，范围1-10000
        res = await this.postChunk(formData, index);
        if (res.code !== 200) {
          this.$message.error(res.msg);
          this.handleCancel(); // 取消上传
          this.status = Status.wait;
          break;
        }
        if (i >= this.data.length - 1) {
          if (res.code === 200) {
            this.mergeRequest();
          }
        }
      }
    },

    // 通知服务端合并切片
    async mergeRequest () {
      let partTotalNum = this.data.length;
      let fileHash = this.container.hash; // 文件hash值
      this.$axios.get(
        `/tcc-file/file/multipart/merge?fileHash=${fileHash}&partTotalNum=${partTotalNum}`
      ).then(res => {
        if (res.code === 200) {
          this.triggerChange(res.data);
          this.container.file.url = this.ossPath + res.data;
          this.fileList = [this.container.file];
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg);
          this.handleCancel(); // 取消上传
        }
      }).finally(() => {
        this.status = Status.wait;
      })
    },

    // 根据 hash 验证文件是否曾经已经被上传过
    // 没有才进行上传
    async verifyUpload (fileHash) {
      const res = await this.$axios.get(`/tcc-file/file/multipart/fileCheck/${fileHash}`);
      let d = {
        shouldUpload: false
      };
      if (res.code === 200) {
        d.shouldUpload = isBlank(res.data);
        this.container.file.url = this.ossPath + res.data;
        if (!d.shouldUpload) {
          this.triggerChange(res.data);
        }
      }
      return d;
    },

    // 保存每个 chunk 的进度数据
    createProgressHandler (item, e) {
      item.percentage = parseInt(String((e.loaded / e.total) * 100));
    },

    /*
    * 获取上传文件(此函数对外暴露，用于获取上传文件信息)
    * @remarks 调取方式：父级组件引用该组件时设置ref="uploadedFile"后，this.refs["uploadedFile"].getUploadedFile();
    * @returns {Object} file
    */
    getUploadedFile () {
      return this.container.file;
    },

    /*
    * 获取上传文件格式
    * @remarks 调取方式：父级组件引用该组件时设置ref="uploadedFile"后，this.refs["uploadedFile"].getFileFormat();
    * @returns {String} file.type
    */
    getFileFormat () {
      if (this.container.file) return this.container.file.type;
      else return "";
    },

    /*
    * 获取上传文件大小
    * @remarks 调取方式：父级组件引用该组件时设置ref="uploadedFile"后，this.refs["uploadedFile"].getFileSize();
    * @returns {Number} file.size
    */
    getFileSize () {
      if (this.container.file) return this.container.file.size;
      else return 0;
    },

    /*
    * 获取音频时长
    * @remarks 触发方式：父级组件引用该组件时设置ref="uploadedFile"后，this.refs["uploadedFile"].getAudioDuration();
    * @remarks 取值方式：父级组件引用该组件时设置@setDuration="setDuration"， 然后在 setDuration 函数设置时长;
    * @returns {Number} duration
    */
    getAudioDuration () {
      let file = this.container.file;
      // 获取视频或者音频时长
      let url = URL.createObjectURL(file);
      // 经测试，发现audio也可获取视频的时长
      let audioElement = new Audio(url);

      audioElement.addEventListener("loadedmetadata", e => {
        // 异步获取到 时长 后 emit setDuration 方式返回
        this.$emit("setDuration", audioElement.duration);
      });
    },

    /*
    * 清空文件
    * @remarks 触发方式：父级组件引用该组件时设置ref="uploadedFile"后，this.refs["uploadedFile"].clearFile();
    */
    clearFile () {
      this.triggerChange(null);
      this.resetData();
    }

  },
  beforeDestroy () {
    // 清除 worker，释放内存
    this.container.worker = null;
  }
};
</script>
<style scoped lang="scss">
.chunk-file-upload {
  position: relative;
  margin-top: 4px;
  border-radius: 4px;
  .operating-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .tip {
    padding: 2px 0;
    line-height: 1.3em;
    font-size: 12px;
  }
  ::v-deep {
    .ant-upload-list-item {
      margin-top: 1px;
    }
  }
}
</style>
