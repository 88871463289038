/**
 * Wrapper NProgress with style
 * http://ricostacruz.com/nprogress/
 */

import "nprogress/nprogress.css"
import nprogress from "nprogress"

// config
/* nprogress.configure({
  minimum: 0.1,
  easing: 'ease',
  speed: 50,
  trickle: false,
  trickleRate: 0.02,
  trickleSpeed: 800,
  showSpinner: false,
  parent: '#content'
}) */

nprogress.configure({ showSpinner: false })

export default nprogress
