import clonedeep from 'lodash.clonedeep';

export const normalizeColumns = (columns, defaultColumnWidth = 0) => {
  return clonedeep(columns)
    .map(column => {
      if (!column?.key && !column?.dataIndex) {
        if (!column?.scopedSlots && !column?.scopedSlots?.customRender) {
          return null;
        }
        column.dataIndex = column?.scopedSlots?.customRender;
      }

      if (!column.width) {
        column.width = +defaultColumnWidth;
      }

      return column;
    })
    .filter((item) => item);
}

export const elemInModal = (el, modalClassName = 'ant-modal-wrap') => {
  let parentNode = el?.parentNode

  while (parentNode && !parentNode?.classList?.contains(modalClassName)) {
    parentNode = parentNode.parentNode
  }

  return parentNode
}

export function getFilteredValueColumns (state, columns) {
  return flatFilter(
    columns || (state || {}).columns || [],
    column => typeof column.filteredValue !== 'undefined'
  );
}
export function getFiltersFromColumns (state, columns) {
  const filters = {};
  getFilteredValueColumns(state, columns).forEach(col => {
    const colKey = getColumnKey(col);
    filters[colKey] = col.filteredValue;
  });
  return filters;
}
export function getColumnKey (column, index) {
  return column.key || column.dataIndex || index;
}
export function flatFilter (tree, callback) {
  return tree.reduce((acc, node) => {
    if (callback(node)) {
      acc.push(node);
    }
    if (node.children) {
      const children = flatFilter(node.children, callback);
      acc.push(...children);
    }
    return acc;
  }, []);
}
