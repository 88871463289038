<template>
  <span>
    <span style="marginLeft:16px"></span>
    <span class="style-operator" v-if="isShowMagnifier" @click="$emit('toggleQueryForm', !isShowQueryForm)"><img src="@/assets/img/icon/icon_query_gray.png" /></span>
    <span v-if="true" class="style-operator" @click.stop="toogleSize(true)">
      <img src="@/assets/img/icon/icon_density_gray.png"  @click.stop="toogleSize(!isShowSetSize)" />
      <div class="set-table set-table-size" v-if="isShowSetSize">
        <a-row v-for="(size, index) in tableSizeOption" :key="'size' + index" @click.stop="e => e.preventDefault()">
          <a-col @click.stop="onChangeSize(index)" :class="{'current': currentSizeIndex === index}">
            {{ size }}
          </a-col>
        </a-row>
        <span class="arrow-top"></span>
        <span class="arrow-bottom"></span>
      </div>
    </span>
    <span class="style-operator" @click.stop="toogleColumns(true)">
      <img src="@/assets/img/icon/icon_set_gray.png" @click.stop="toogleColumns(!isShowSetColumns)" />
      <a-checkbox-group @change="onChangeColumns" :defaultValue="defaultColumnsValue">
        <div class="set-table set-table-columns" v-if="isShowSetColumns">
          <a-row v-for="(field, index) in columns" :key="'op' + index"  @click.stop="e => e.preventDefault()">
            <a-col>
              <a-checkbox :value="field.dataIndex">{{ field.title }}</a-checkbox>
            </a-col>
          </a-row>
          <span class="arrow-top"></span>
          <span class="arrow-bottom"></span>
        </div>
      </a-checkbox-group>
    </span>
  </span>
</template>

<script>
import config from "@/config/defaultSettings"
export default {
  name: "TableOperator",
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    // 对应 Column 的 dataIndex 参数 数组，用于默认显示table 的 th 相
    defaultColumnsDataIndex: {
      type: Array,
      default: () => []
    },
    // 如果同一个路由下面有两个table需要此功能，必填，用于区分两个 table 的 columns 字段集
    uniqueCode: {
      type: String,
      default: "one"
    },
    isShowQueryForm: {
      type: Boolean,
      default: true
    },
    isShowMagnifier: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      defaultColumnsValue: [],
      isShowSetColumns: false,
      tableSizeOption: config.recordsTable.sizeOptions,
      currentSizeIndex: config.recordsTable.defaultSizeIndex,
      isShowSetSize: false
    }
  },
  created () {
    const sessionStorageColumns = JSON.parse(sessionStorage.getItem(`${this.$route.name}_${this.uniqueCode}_RECORDS_TABLE_COLUMNS`)) || [];
    let temColumns = [];
    if (sessionStorageColumns.length) {
      temColumns = this.viewColums(sessionStorageColumns);
    } else {
      if (this.defaultColumnsDataIndex.length) {
        temColumns = this.viewColums(this.defaultColumnsDataIndex);
      } else {
        temColumns = this.columns;
      }
    }
    this.$emit("onChangeColumns", temColumns);
    this.defaultColumnsValue = temColumns.reduce((acc, cur) => {
      acc.push(cur.dataIndex);
      return acc;
    }, []);
  },
  mounted () {
    document.addEventListener("click", () => {
      this.isShowSetColumns = false;
      this.isShowSetSize = false;
    });
  },
  beforeDestroy () {
    document.removeEventListener("click", () => {
      this.isShowSetColumns = false;
      this.isShowSetSize = false;
    })
  },

  methods: {
    // 切换表格大小
    toogleSize (flag) {
      this.isShowSetSize = flag;
      this.isShowSetColumns = false;
    },

    // 切换columns
    toogleColumns (flag) {
      this.isShowSetColumns = flag;
      this.isShowSetSize = false;
    },

    // 控制表格大小
    onChangeSize (value) {
      this.isShowSetSize = false;
      this.currentSizeIndex = value;
      this.$emit("onChangeSize", config.recordsTable.sizeClassOptions[value]);
    },

    // 控制表头显示
    onChangeColumns (values) {
      sessionStorage.setItem(`${this.$route.name}_${this.uniqueCode}_RECORDS_TABLE_COLUMNS`, JSON.stringify(values));
      const currentcolums = this.viewColums(values);
      this.$emit("onChangeColumns", currentcolums);
    },
    // 过滤表头显示字段
    viewColums (values) {
      let colums = this.columns.reduce((acc, cur) => {
        if (values.includes(cur.dataIndex)) {
          acc.push(cur);
        }
        return acc;
      }, []);
      return colums;
    }
  }
}
</script>

<style lang="scss" scoped>
.style-operator{
  position: relative;
  width: 32px;
  height: 32px;
  padding: 8px;
  margin-left: 16px;
  background: #EFF0F5;
  border-radius: 4px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  .set-table-size {
    min-width: 100px;
    .ant-col {
      text-align: center;
    }
    .current {
      color: #446BFF;
      background: #EFF0F5;
    }
  }
  .set-table-columns {
    min-width: 150px;
    .ant-col {
      text-align: left;
    }
  }
  .set-table {
    position: absolute;
    top: 40px;
    right: -16px;
    padding: 8px 0;
    background: #ffffff;
    border: 1px solid #e3e5eb;
    border-radius: 5px;
    box-shadow: 0px 1px 8px 0px rgba(59,55,85,0.16);
    z-index: 9;
    .ant-col {
      padding: 0 16px;
      line-height: 32px;
      &:hover {
        background: #EFF0F5;
      }
    }
    .arrow-top {
      position: absolute;
      right: 22px;
      height: 0px;
      width: 0px;
      line-height: 0;
      border-width: 10px;
      border-color: transparent transparent #CCC transparent;
      border-style: dashed dashed solid dashed;
      top: -20px;
    }
    .arrow-bottom {
      position: absolute;
      right: 22px;
      height: 0px;
      width: 0px;
      border-width: 10px;
      border-color: transparent transparent #FFF transparent;
      border-style: dashed dashed solid dashed;
      top: -19px;
      line-height: 0;
    }
  }
}
</style>
