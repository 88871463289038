import { asyncAppComponent } from "@/utils/async.js"

/**
 * 生成导航菜单路由
 * @method generateDynamicRoutes
 * @param {Object} data 菜单
 * @return {Object} 动态 routes 配置
 */
export function generateDynamicRoutes (data) {
  let routes = [{
    path: "/",
    redirect: "/home",
    name: "m0000000000000000001",
    component: () => import(/* webpackChunkName: "home" */ "@/components/layouts/BasicLayout.vue"),
    children: [
      ...generator(formatToFlatRouter(data))
    ]
  }, {
    "path": "*", "redirect": "/404", "hidden": true
  }]
  return routes
}

/**
* 把嵌套菜单转换成平级菜单，解决 vue 多重子路由嵌套缓存(keep-alive)失效问题。
* @method formatToFlatRouter
* @param {Object} routes 菜单路由
*/
const formatToFlatRouter = (routes, newRoutes = []) => {
  routes.map(item => {
    if (item.children && item.children.length > 0) {
      formatToFlatRouter(item.children, newRoutes);
      delete item.children;
    }
    newRoutes.push(item);
  })
  return newRoutes;
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 * @method generator
 * @param {Object} routerMap 菜单路由
 * @return {Object} currentRoute 当前 route 配置
 */
export const generator = (routerMap) => {
  if (routerMap && Array.isArray(routerMap)) {
    return routerMap.map(item => {
      const { title, titleI18n, icon, permission, topMenuId, iframeSrc, subsystemId } = item.meta || {}
      const componentsrc = item.component?.indexOf("layouts") >= 0 ? "components" + item.component : "views" + item.component;
      const currentRoute = {
        path: item.path, // 路由 path
        name: item.name, // 路由名称(唯一)
        // component: () => import("@/" + componentsrc + ".vue"), // 该路由对应页面的 组件 (动态加载)
        component: resolve => require(["@/" + asyncAppComponent(componentsrc) + ".vue"], resolve),
        hidden: !!item.hidden, // 控制路由是否显示在 sidebar
        meta: { title, titleI18n, icon, permission, topMenuId, iframeSrc, keepAlive: true, subsystemId } // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      }
      // 缓存该路由
      item.meta.keepAlive && (currentRoute.meta.keepAlive = true)
      // 隐藏该菜单
      item.meta.hidden && (currentRoute.meta.hidden = true)
      // 重定向
      item.redirect && (currentRoute.redirect = item.redirect)
      // 是否有子菜单，并递归处理
      if (item.children && item.children.length > 0) {
        // 递归
        currentRoute.children = generator(item.children, currentRoute)
      }
      // console.log(currentRoute);
      return currentRoute
    })
  }
  return [];
}
