<script>
import { Table, Pagination } from 'ant-design-vue';
import ColumnsMixins from './columns.mixins'

import { normalizeColumns, elemInModal } from './utils';

// import scheduler from './scheduler'

const PREFIX = 'DRAGGABLE-';

function getColKey (col) {
  if (col.key) {
    return PREFIX + col.key;
  }

  if (col.dataIndex) {
    return PREFIX + col.dataIndex;
  }

  if (col.scopedSlots && col.scopedSlots.customRender) {
    return PREFIX + col.scopedSlots.customRender;
  }

  return '';
}

export default {
  name: 'ResizableTable',

  mixins: [ColumnsMixins],

  props: {
    columns: {
      type: Array,
      default: () => []
    },

    // 默认的列宽度
    defaultColumnWidth: {
      type: Number,
      default: 100
    },

    // 列最小可拖拽宽度限制
    minWidth: {
      type: [String, Number],
      default: 30
    },

    // 列显示的内容是否超出自动换行显示
    contentWordWrap: {
      type: Boolean,
      default: false
    },

    scroll: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    this.ATableComponents = {
      header: {
        cell: null
      }
    }

    this.guidelineId = `draggable__guideline`

    return {
      currentColumns: [],
      cached: {},

      lastColumnWidth: 0,

      cachedChangeArgs: [] // 缓存表格 change 事件的参数
    }
  },

  computed: {
    tableWidth () {
      return this.currentColumns.reduce((prev, item) => {
        if (item.hasOwnProperty("fixed") && item.fixed !== false) {
          return prev;
        }
        const width = Number(item.width);
        return prev + (Object.is(NaN, width) ? 0 : width);
      }, 0) + this.lastColumnWidth
    },

    currentScroll () {
      const { scroll = {}, tableWidth } = this;
      return {
        ...scroll,
        x: tableWidth
      }
    },

    localData () {
      const filters = this.getDefaultFilters(this.currentColumns) || {}
      const sorterColumn = this.getDefaultSortOrder(this.currentColumns) || {}

      const sorterFn = this.getSorterFn(sorterColumn)

      let data = (this.$attrs.dataSource || this.$attrs['data-source'] || []).slice(0)

      if (sorterFn) {
        // 使用新数组，避免改变原数组导致无限循环更新
        // https://github.com/vueComponent/ant-design-vue/issues/2270
        data = this.recursiveSort([...data], sorterFn);
      }

      if (filters) {
        Object.keys(filters).forEach(columnKey => {
          const col = this.currentColumns.find(item => item.dataIndex === columnKey);
          if (!col) {
            return;
          }
          const values = filters[columnKey] || [];
          if (values.length === 0) {
            return;
          }
          const onFilter = col.onFilter;
          data = onFilter
            ? data.filter(record => {
              return values.some(v => onFilter(v, record));
            })
            : data;
        });
      }

      return data
    }
  },

  watch: {
    columns: {
      deep: true,
      immediate: true,
      handler (cols) {
        this.setCurrentColumns(normalizeColumns(cols, this.defaultColumnWidth))
        // scheduler(() => {
        //   this.setCurrentColumns(normalizeColumns(cols, this.defaultColumnWidth))
        // })
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.initDrag();
    })
  },

  activated () {
    this.$nextTick(() => {
      this.initDrag();
    })
  },

  deactivated () {
    this.removeGuideline();

    this.removeEventListener();
  },

  beforeDestroy () {
    this.removeGuideline();

    this.removeEventListener();
  },

  methods: {
    async setCurrentColumns (columns) {
      const fixedLeft = columns.filter(item => item.fixed === true || item.fixed?.toLowerCase() === 'left')
      const fixedRight = columns.filter(item => item.fixed?.toLowerCase() === 'right')
      const innerColumns = columns.filter(item => !item.fixed)

      this.currentColumns = [
        ...fixedLeft,
        ...innerColumns,
        ...fixedRight
      ]
      this.initColumns();

      await this.$nextTick()
      if (document) {
        if (!this.$refs?.resizableTable?.$el) {
          return
        }
        const oTable = this.$refs.resizableTable.$el

        if (oTable) {
          const rect = oTable.getBoundingClientRect()

          // 最后一列动态计算需要不能忽略 Fixed 列的宽度
          const tableWidth = this.currentColumns.reduce((prev, item) => {
            const width = Number(item.width);
            return prev + (Object.is(NaN, width) ? 0 : width);
          }, 0)

          // 增加 4px 的误差计算
          if (rect.width - 4 > tableWidth && (fixedLeft.length !== 0 || fixedRight.length !== 0)) {
            this.lastColumnWidth = innerColumns.at(-1)?.width || 0
            delete innerColumns.at(-1).width

            this.currentColumns = [
              ...fixedLeft,
              ...innerColumns,
              ...fixedRight
            ]

            this.initColumns();
          }
        }
      }
    },

    initColumns () {
      const columns = this.currentColumns;

      const resizeableTitle = (h, props, children) => {
        const { key, ...restProps } = props;
        let col = {};

        if (key === 'selection-column') {
          col = {};
        } else {
          col = columns.find((item) => {
            const k = item.dataIndex || item.key;
            return k === key;
          });
        }

        if (!col?.width) {
          return <th {...restProps}>{children}</th>;
        }

        return (
          <th
            {...restProps}
            class="ant-table-resize-table-th">
            {children}
            <div
              class="ant-table-draggable-handle"
              data-key={ getColKey(col) }
            />
          </th>
        );
      }

      this.ATableComponents.header.cell = resizeableTitle;

      // HACK，触发 a-table 重新计算列高
      setTimeout(() => {
        this.currentColumns = this.currentColumns.slice(0)
      }, 400)
    },

    // 初始化辅助线 drag
    initDrag () {
      const oTable = this.$refs.resizableTable;
      this.createGuideline();

      this.onMouseDown = this._onMouseDown;
      this.onMOuseMove = this._onMouseMove;
      this.onMOuseUp = this._onMouseUp;
      oTable.$el.addEventListener('mousedown', this.onMouseDown, false);
    },

    // 生成辅助线
    createGuideline () {
      const { guidelineId } = this;

      const parentNode = elemInModal(this.$refs.resizableTable.$el)
      let zIndex = 1
      if (parentNode) {
        zIndex += window.getComputedStyle(parentNode)?.zIndex || 0
      }

      const oGuideline = document.createElement('div');
      // 采用随机ID 保证唯一性
      oGuideline.id = guidelineId;
      oGuideline.style.position = 'fixed';
      oGuideline.style.left = '0';
      oGuideline.style.top = '0';
      // oGuideline.style.zIndex = '2147483647';
      oGuideline.style.zIndex = zIndex;
      oGuideline.style.height = '50px';
      oGuideline.style.borderLeft = '1px dotted #446bff';
      oGuideline.style.cursor = 'col-resize';
      document.body.appendChild(oGuideline);
      this.guideline = oGuideline;
    },

    // 移除辅助线
    removeGuideline () {
      this.guideline && this.guideline.remove();
      this.guideline = null;
    },

    // 设置辅助线 top left height 样式
    setGuidelineStyle (left) {
      const { top, height } = this.getTableBoundingClientRect();
      const guideline = this.guideline;
      this.toggleGuideline(true);
      guideline.style.height = height + 'px';
      guideline.style.top = top + 'px';
      guideline.style.left = left + 'px';
    },

    // 显示/隐藏 辅助线
    toggleGuideline (visible) {
      this.guideline.style.display = visible ? 'block' : 'none';
    },

    _onMouseDown (e) {
      const { target, clientX } = e;
      if (target.classList.contains('ant-table-draggable-handle')) {
        this.setGuidelineStyle(clientX)
        document.addEventListener('mousemove', this.onMOuseMove, false);
        document.addEventListener('mouseup', this.onMOuseUp, false);

        this.cached = {
          key: target.getAttribute('data-key'),
          left: clientX
        };
      }
    },

    _onMouseMove (e) {
      this.setGuidelineStyle(e.clientX);
      this.toggleTableUserSelectable(false);
    },

    _onMouseUp (e) {
      document.removeEventListener('mousemove', this.onMOuseMove, false);
      document.removeEventListener('mouseup', this.onMOuseUp, false);
      this.toggleGuideline(false);
      this.toggleTableUserSelectable(true);

      const { key, left } = this.cached;
      const { minWidth } = this;

      if (key && left) {
        this.currentColumns = this.currentColumns.map((col) => {
          const _key = getColKey(col);
          if (_key && _key === key) {
            col.width = Math.max(+minWidth, col.width + (e.clientX - left))
          }
          return col;
        })
      }
    },

    getTableBoundingClientRect () {
      const oTable = this.$refs.resizableTable;
      try {
        const { top, height } = oTable.$el.querySelector('.ant-table').getBoundingClientRect();
        return { top, height }
      } catch {
        return {
          top: 0,
          height: 0
        }
      }
    },

    // 表格内容是否可选中
    // 解决拖拽时会选中表格内容的问题
    toggleTableUserSelectable (selectable = false) {
      if (!selectable) {
        document.body.style.userSelect = 'none';
      } else {
        document.body.style.userSelect = 'unset';
        window.getSelection ? window.getSelection().removeAllRanges() : document.selection.empty();
      }
    },

    removeEventListener () {
      try {
        this.$refs.resizableTable.$el.removeEventListener('mousedown', this.onMOuseDown, false);
        document.removeEventListener('mousemove', this.onMOuseMove, false);
        document.removeEventListener('mouseup', this.onMOuseUp, false);
      } catch {}
    },

    handlePaginationChange (current, pageSize) {
      const currentPagination = {
        ...(this.$attrs.pagination || {}),
        current,
        pageSize
      }

      if (this.cachedChangeArgs.length === 0) {
        const filters = this.getDefaultFilters(this.currentColumns)
        const sorter = {}

        const sorterColumn = this.getDefaultSortOrder(this.currentColumns)
        sorter.column = sorterColumn.sSortColumn
        sorter.order = sorterColumn.sSortOrder
        sorter.field = sorterColumn.sSortColumn?.dataIndex
        sorter.columnKey = sorterColumn.sSortColumn?.dataIndex || sorterColumn.sSortColumn?.key

        this.cachedChangeArgs = [
          currentPagination,
          filters,
          sorter,
          {
            currentDataSource: this.localData
          }
        ]
      } else {
        this.cachedChangeArgs.splice(0, 1, {
          ...(this.$attrs.pagination || {}),
          current,
          pageSize
        })
      }
      if (this.$listeners?.change) {
        this.$listeners.change(...this.cachedChangeArgs)
      }
    }
  },

  render (h) {
    const slots = Object.keys(this.$slots)
      .reduce((arr, key) => arr.concat(this.$slots[key]), [])
      .map(vnode => {
        vnode.context = this._self ||'-'
        return vnode
      });

    const _this = this;

    const total = this.$attrs.pagination?.total || this.localData.length

    let className = 'resizable__normal__table'

    if (this.contentWordWrap) { // 如果需要自动换行则引用另外的样式类
      className = 'resizable__wrap__table'
    }

    return (
      <div class="tcc-custom-table">
        {
          h(Table, {
            ref: 'resizableTable',
            class: className,
            props: {
              ...this.$attrs,
              ...this.$props,
              dataSource: this.localData,
              pagination: false,
              columns: this.currentColumns,
              bordered: true,
              components: this.ATableComponents,
              scroll: this.currentScroll
            },
            on: {
              ...this.$listeners,
              change (...args) {
                // 拦截change事件，缓存提交数据
                _this.cachedChangeArgs = args
                _this.$listeners.change && _this.$listeners.change(...args);
              }
            },
            scopedSlots: this.$scopedSlots
          }, slots)
        }
        {
          _this.$attrs.pagination !== false && h(
            Pagination,
            {
              props: {
                ..._this.$attrs.pagination,
                total
              },
              on: {
                change: _this.handlePaginationChange,
                showSizeChange: _this.handlePaginationChange
              },
              class: ["ant-table-pagination"]
            }
          )
        }
      </div>
    )
  }
}
</script>

<style lang="scss" scoped>
@import './normal.scss';
@import './wrap.scss';
</style>
