<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import { mapMutations } from "vuex"
import { fetchI18n } from '@/locales'
import { clearParameterCache } from "@/utils/cacheQuery.js"
import { ACCESS_TOKEN } from "@/store/mutation-types"

export default {
  beforeCreate () {
    clearParameterCache(); // 清除应用查询参数缓存，需求-刷新清除
  },
  created () {
    this.forgeHttpRequest();
  },
  mounted () {
    // 页面在一个 <iframe> 中打开
    if (window.self !== window.top) {
      window.addEventListener('message', this.handleMessage);
      // 告诉父组件准备好接收消息了
      window.parent.postMessage({
        cmd: 'ready-for-receiving'
      }, '*')
    }
  },
  destroyed () {
    if (window.self !== window.top) {
      // 页面在一个 <iframe> 中打开
      // 注意移除监听！
      window.removeEventListener('message', this.handleMessage)
    }
  },
  computed: {
    locale () {
      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    },
    isLogin () {
      return !!this.$store.state.user.token
    }
  },
  watch: {
    isLogin: {
      handler (newV) {
        // newV && fetchI18n(this.$i18n);
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      setDeployType: "SET_DEPLOYTYPE",
      setTenantProject: "SET_TENANTPROJECT"
    }),
    // 伪造空请求，获取响应头信息（orgId）
    forgeHttpRequest () {
      var req = new XMLHttpRequest();
      req.open("GET", document.location, false);
      req.send(null);
      let deployType = req.getResponseHeader("deploy-type");
      this.setDeployType(deployType);
      let tenantProject = req.getResponseHeader("tenant-project");
      this.setTenantProject(tenantProject);
    },

    // 根据上面制定的结构来解析 iframe 内部发回来的数据
    handleMessage (event) {
      const data = event.data
      switch (data.cmd) {
        case 'set-full-screen':
          this.$ls.set(ACCESS_TOKEN, data.params.token, 7 * 24 * 60 * 60 * 1000);
          this.$store.commit("SET_ISIFRAMEPAGE", true);
          break
      }
    }
  }
};
</script>
<style>
#app {
  height: 100%;
}
</style>
