import Vue from "vue";
import { QueryList, QuerySider, QueryParam, QueryResult, RecordsTable, AED, ScrollView, Ellipsis, LayoutContent } from "@/components";

Vue.component("c-query-list", QueryList);
Vue.component("c-query-sider", QuerySider);
Vue.component("c-query-param", QueryParam);
Vue.component("c-query-result", QueryResult);
Vue.component("c-records-table", RecordsTable);
Vue.component("c-add-edit-detail", AED);
Vue.component("c-scroll-view", ScrollView);
Vue.component("ellipsis", Ellipsis);
Vue.component("c-layout-content", LayoutContent);
