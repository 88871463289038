/*!
 * @file moment.js
 * @desc 格式化时间、日期
 * @source https://momentjs.com/
 * @version v2.2.0
 */

import moment from "moment"
import "moment/locale/zh-cn"
moment.locale("zh-cn")
export default moment
