/*!
 * @file api/org.js
 * @desc 角色管理
 */

const url = "/system/role";
const urlAuth = url + "/auth";

export default ({ request, baseURL }) => ({
  // 查询角色
  // queryRole (params) { return axios.get(url, { params }) },

  // 根据ID查询角色
  queryRoleById (params) {
    return request({
      url: `${baseURL}${url}/${params.id}`,
      method: "get",
      params
    })
  },

  // 新增角色
  addRole (data) {
    return request({
      url: `${baseURL}${url}`,
      method: "post",
      data
    })
  },

  // 编辑角色
  editRole (data, id) {
    return request({
      url: `${baseURL}${url}/${id}`,
      method: "put",
      data
    })
  },

  // 获取角色已有的菜单和权限
  queryRolePerm (id) {
    return request({
      url: `${baseURL}/system/menu/roleMenuTreeselect/${id}`,
      method: "get"
    })
  },

  // 授权角色
  authRole (data, id) {
    return request({
      url: `${baseURL}${urlAuth}/${id}`,
      method: "put",
      data
    })
  }

})
