/*!
 * @file api/login.js
 * @desc 登录系统
 */
import qs from "qs"
export default ({ request, baseURL }) => ({

  /**
   * 登录
   * @method login func
   * @param {Object} data post 入参
   * @return {Object} Promise 对象
   */
  login (data) {
    const { orgId = "" } = qs.parse(window.location.href.split("?")[1]); // 获取当前页面地址中的orgId参数的值
    return request({
      headers: { orgId },
      url: `${baseURL}/auth/login`,
      method: "post",
      data
    })
  },

  /**
   * 手机号登录
   * @method phoneLogin func
   * @param {Object} data post 入参
   * @return {Object} Promise 对象
   */
  phoneLogin (data) {
    return request({
      url: `${baseURL}/ofa-admin/open-api/login`,
      method: "post",
      data
    })
  },

  /**
   * 用户名+密码+验证码登录
   * @method userNameLogin func
   * @param {Object} data post 入参
   * @return {Object} Promise 对象
   */

  byValidCodeLogin (data) {
    const { orgId = "" } = qs.parse(window.location.href.split("?")[1]); // 获取当前页面地址中的orgId参数的值
    return request({
      headers: { orgId },
      data,
      method: "post",
      url: `${baseURL}/ofa-admin/open-api/login/byValidCode`
    })
  },

  /**
   * 获取验证码
   * @method getVerifyCode func
   * @return {Object} Promise 对象
   */
  getVerifyCode () {
    return request({
      url: `${baseURL}/ofa-admin/verifyCode`,
      method: "get"
    })
  },

  /**
   * 获取登录用户信息
   * @method getLoginUser func
   * @return {Object} Promise 对象
   */
  getLoginUser (token) {
    let config = {
      url: `${baseURL}/system/user/getInfo`,
      method: "get"
    }
    if (token) {
      // 带指定 token 查询登录用户信息
      config.headers = { "Authorization": token }
    }
    return request(config)
  },

  /**
   * 修改登录用户密码
   * @method login func
   * @param {Object} data post 入参
   * @return {Object} Promise 对象
   */
  changePassword (data, id, token) {
    let config = {
      url: `${baseURL}/system/user/profile/updatePwd`,
      method: "put",
      params: data
    }
    if (token) {
      // 带指定 token 修改用户密码
      config.headers = { "OFA-TOKEN": token }
    }
    return request(config)
  },

  // 判断是否开启等保登录
  getLevelProtectionLogin () {
    return request({
      url: `${baseURL}/ofa-admin/open-api/login/config`,
      method: "get"
    })
  },

  // 退出登录
  logOut () {
    return request({
      url: `${baseURL}/auth/logout`,
      method: "delete"
    })
  },

  // 15天内首次登录
  firstLogin (data) {
    return request({
      url: `${baseURL}/ofa-admin/open-api/login/byValidCode`,
      method: "post",
      data
    })
  },

  // 绑定手机号码
  bindPhone (data) {
    return request({
      url: `${baseURL}/ofa-admin/open-api/bingPhone`,
      method: "post",
      data
    })
  },

  // 第三方登录-授权登录
  thirdLogin (data) {
    return request({
      url: `${baseURL}/ofa-admin/ofaSubsystem/oauth2/authorize`,
      method: "post",
      data
    })
  },

  /**
   * 登录界面图标和标题文字配置
   * @method loginConfig func
   * @return {Object} Promise 对象
   */
  loginConfig () {
    return request({
      url: `${baseURL}/ofa-admin/open-api/login/config`,
      method: "get"
    })
  },

  // 检查当前token是否有效
  checkToken (params) {
    return request({
      url: `${baseURL}/auth/refresh`,
      method: 'get',
      // params,
      headers: { "feign-token": "AC11000128E731CEFDE03BEAD771000A" }
    })
  }
})
