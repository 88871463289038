let constRoutes = [];
// 以下方法是读取./modules/目录下的所有js文件
const routerContext = require.context("./modules/", true, /\.js$/);
routerContext.keys().forEach(filePath => {
  const routerModule = routerContext(filePath);
  // 兼容 import export 和 require module.export 两种规范 Es modules commonjs
  constRoutes = [...constRoutes, ...(routerModule.default || routerModule)];
});

export default constRoutes;
