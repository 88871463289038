<template>
  <a-input
    :value="code"
    :placeholder="placeholder"
    :maxLength="maxLength"
    :size="size"
    @change="handleCodeChange"
  >
    <!-- <a-icon slot="prefix" :type="prefixIcon" v-if="prefixIcon !== ''" style="color:#446BFF;fontSize:16px" /> -->
    <img style="width:16px;height:16px;" slot="prefix" :src="require('@/assets/img/icon/login_icode.png')" alt="icode">
    <a href="javascript:;" @click="sendSMS" slot="addonAfter" :class="{ gray: count > 0 }">{{curBtnTxt}}</a>
  </a-input>
</template>

<script>
import { isBlank } from "@/utils/dataType"
export default {
  props: {
    // 值
    value: {
      type: String
    },
    // 提示
    placeholder: {
      type: String,
      default: "请输入短信验证码"
    },
    // 最大长度
    maxLength: {
      type: Number,
      default: 6
    },
    size: {
      type: String,
      default: "default"
    },
    // 按钮文本
    btnTxt: {
      type: String,
      default: "获取验证码"
    },
    // 入参数
    params: {
      required: true
    },
    // api 地址
    url: {
      type: String,
      required: true
    },
    prefixIcon: {
      type: String,
      default: ""
    },
    resMethods: {
      type: String,
      default: "get"
    }
  },
  data () {
    return {
      code: "",
      count: "",
      timer: null, // 定时器唯一值
      curBtnTxt: "",
      // 国际化语句
      usernameTip: this.$t('user.login.account.placeholder'), // 请输入管理员帐号或手机号
      passwordTip: this.$t('user.login.password.placeholder'), // 请输入密码
      phoneTip: this.$t('user.login.phone.placeholder'), // 请输入手机号
      graphicCodeTip: this.$t('user.login.graphic-code.placeholder'), // 请输入图形码
      veriCodeTip: this.$t('user.login.veri-code.placeholder'), // 请输入验证码
      getVeriCodeBtnTip: this.$t('user.login.get-veri-code.btn-text'), // 获取验证码
      lostParamError: this.$t('user.login.lost-param.error'), // 缺少参数,
      lostVericodeError: this.$t('user.login.lost-vericode.error') // '缺失获取验证码地址'
    }
  },
  created () {
    this.curBtnTxt = this.btnTxt;
  },
  watch: {
    value (val) {
      this.code = val;
    },
    btnTxt (val) {
      this.curBtnTxt = val;
    }
  },
  methods: {
    // 改变值
    handleCodeChange (e) {
      this.triggerChange(e.target.value);
    },
    // 触发
    triggerChange (changedValue) {
      this.$emit("change", changedValue);
    },
    // 获取验证码
    sendSMS () {
      if (this.count > 0) {
        return;
      }
      let { url, params } = this;
      if (isBlank(url)) {
        this.$message.error(this.lostVericodeError);
      } else if (isBlank(params)) {
        this.$message.error(this.lostParamError);
      } else if (params.hasOwnProperty("phone") && isBlank(params.phone)) {
        // 当传过来当参数有 phone 键时
        this.$message.error(this.phoneTip);
      } else if (params.hasOwnProperty("captcha") && isBlank(params.captcha)) {
        // 当传过来当参数有 captcha 键时
        this.$message.error(this.graphicCodeTip);
      } else if (params.hasOwnProperty("username") && isBlank(params.username)) {
        this.$message.error(this.usernameTip);
      } else if (params.hasOwnProperty("password") && isBlank(params.password)) {
        this.$message.error(this.passwordTip);
      } else if (params.hasOwnProperty("code") && isBlank(params.code)) {
        this.$message.error(this.graphicCodeTip);
      } else {
        switch (this.resMethods.toLowerCase()) {
          case "get":
            this.$axios.get(url, {
              params
            }).then((res) => {
              if (res.code === 200) {
                this.countDown();
                this.$resMsg("success", "短信已发送，请查看");
              } else {
                this.$resMsg("error", res.msg);
                this.$emit("getCaptcha");
              }
            });
            break;
          case "post":
            this.$axios.post(url, params).then((res) => {
              if (res.code === 200) {
                this.countDown();
                this.$resMsg("success", "短信已发送，请查看");
              } else {
                this.$resMsg("error", res.msg);
                this.$emit("getCaptcha");
              }
            });
        }
      }
      // else {
      //   this.$axios.get(url, {
      //     params
      //   }).then((res) => {
      //     if (res.code === 200) {
      //       this.countDown();
      //       this.$resMsg("success", "短信已发送，请查看");
      //     } else {
      //       this.$resMsg("error", res.msg);
      //       this.$emit("getCaptcha");
      //     }
      //   });
      // }
    },
    // 倒计时
    countDown () {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.curBtnTxt = `${this.count} 秒`;
            this.count--;
          } else {
            clearInterval(this.timer);
            this.curBtnTxt = this.btnTxt;
            this.timer = null;
          }
        }, 1000);
      }
    }
  },
  beforeDestroy () {
    // 销毁组件前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
</script>

<style scoped lang="scss">
.gray {
  cursor: default;
  text-decoration: none;
}
::v-deep {
  .ant-input-group-addon {
    border: none;
    border-bottom: 2px solid #EBEDF5;
    background: none;
  }
}
</style>
