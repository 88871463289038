import router from "@/router"
import { randomUUID } from "@/utils/util";
import { asyncComponent } from "@/utils/async"
import config from "@/config/defaultSettings"
import { SUBSYSTEM_ID } from "@/store/mutation-types"
import store from "@/store"
const shortid = require("shortid");

/**
 * 开发者动态单个添加路由
 * @param { routeConfig } Object 路由配置信息
 * @param { routeConfig.path } String 选填 路由路径
 * @param { routeConfig.name } String 选填 路由名称
 * @param { routeConfig.meta } Object 必填 { title: "", titleI18n: "", keepAlive: true, permission: [] }
 * @param { routeConfig.component } String 必填 组件路径 e.g. @/views/folder/login/xxx.vue 则填 /folder/login/xxx.vue
 * @param { routeConfig.xxx } 等路由配置
 * @return route
 */

export function addRoute (routeConfig) {
  const component = asyncComponent(routeConfig.component);
  const parentName = "m0000000000000000001"; // 固定值，别改
  const { curRoutePer, topMenuId } = store.state.permission;
  const subsystemId = process.env.VUE_APP_SUBSYSTEM_ID || sessionStorage.getItem(config.storageOptions.namespace + SUBSYSTEM_ID);
  let route = {
    path: "/" + shortid.generate(), // 路由 path
    name: "m" + randomUUID(), // 路由名称(唯一)
    meta: { keepAlive: true, hidden: true, permission: curRoutePer, topMenuId, subsystemId } // 默认开启路由缓存
  }
  let cache = {};
  Object.assign(route.meta, routeConfig.meta);
  delete routeConfig.meta;
  Object.assign(cache, route, routeConfig);
  Object.assign(route, routeConfig, { component });
  // 动态添加可访问路由表
  router.addRoute(parentName, route);
  // 获取本地缓存
  let arr = getCacheRoute();
  arr.push(cache);
  // 缓存添加的路由
  sessionStorage.setItem(`${config.storageOptions.namespace}ADDROUTE_CONFIG`, JSON.stringify(arr));
  return route;
}

// 获取开发者动态添加的路由缓存
export function getCacheRoute () {
  return JSON.parse(sessionStorage.getItem(`${config.storageOptions.namespace}ADDROUTE_CONFIG`)) || [];
}

// 设置开发者动态添加的路由缓存
export function setCacheRoute (routes) {
  sessionStorage.setItem(`${config.storageOptions.namespace}ADDROUTE_CONFIG`, JSON.stringify(routes));
}

// 移除开发者动态添加的路由缓存
export function removeCacheRoute (name) {
  let cacheRoute = getCacheRoute();
  for (let i = 0; i < cacheRoute.length; i++) {
    if (cacheRoute[i].name === name) {
      cacheRoute.splice(i, 1);
      setCacheRoute(cacheRoute);
      break;
    }
  }
}
