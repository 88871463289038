/*!
 * Custom axios instance
 * axios: https://www.npmjs.com/package/axios
 * version: 0.0.1,
 */

import Vue from "vue";
import axios from "axios";
import { errorHandle } from "./index";
import { ACCESS_TOKEN, APP_LANGUAGE, TENANT_ID, APP_PLATFORM } from "@/store/mutation-types";
import sysconfig from "@/config/defaultSettings";

export const configDefault = {
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 300000, // 请求的超时时间
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  },
  withCredentials: true // 允许携带cookie
};

// 创建 axios 实例
const instance = axios.create(configDefault);

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    const token = Vue.ls.get(ACCESS_TOKEN);
    token && (config.headers["Authorization"] = token);
    const lang = Vue.ls.get(APP_LANGUAGE);
    lang && (config.headers["Accept-Language"] = lang);

    const userInfo = Vue.ls.get('LOGIN_USER_INFO')
    const cPlatform = userInfo?.zwPlatform || "0";
    const cTenantId = userInfo?.zwTenantId || "0";
    cPlatform && (config.headers[APP_PLATFORM] = cPlatform || 0);
    if (!config.headers[TENANT_ID]) {
      const tenantId =
        process.env.tenant_id || cTenantId ||
        "0";
      tenantId && (config.headers[TENANT_ID] = tenantId);
    }
    // if (!config.headers["SUBSYSTEM-ID"]) {
    //   const subsystemId = process.env.VUE_APP_SUBSYSTEM_ID || sessionStorage.getItem(sysconfig.storageOptions.namespace + SUBSYSTEM_ID);
    //   subsystemId && (config.headers["SUBSYSTEM-ID"] = subsystemId);
    // }

    // 如果是POST或PUT请求，且data存在，则去除data中字符串参数首尾空格
    if ((config.method === 'post' || config.method === 'put') && config.data) {
      for (let key in config.data) {
        if (typeof config.data[key] === 'string') {
          config.data[key] = config.data[key].trim();
        }
      }
    }
    // 如果是GET请求，且params存在，则去除params中字符串参数首尾空格
    if (config.method === 'get' && config.params) {
      for (let key in config.params) {
        if (typeof config.params[key] === 'string') {
          config.params[key] = config.params[key].trim();
        }
      }
    }

    return config;
  },
  (error) => Promise.error(error)
);

// 添加响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    if (res.status !== 200 || res.data.code !== 200) {
      errorHandle(res);
    }
    /*
    下载的时，返回二进制流，如果需要获取响应头信息（response.headers），请求时设置如下：
    axios({
      method: "...",
      url: "...",
      data: {...},
      responseType: "blob", // 二进制类型的大对象
      responseNode: "root" // 此标识用来判断直接返回:res，而不是res.data
    }
    */
    return Promise.resolve(res.config.responseNode === "root" ? res : res.data);
  },
  // res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res),
  // 请求失败
  (err) => {
    errorHandle(err);
    return Promise.reject(err);
  }
);

export default instance;
