import Vue from "vue";
import store from "@/store"
import { isBlank, isBoolean, isString, isObject } from "@/utils/dataType"

function getCurRoutePerm (value) {
  const permissions = store.state.permission.curRoutePer;
  let b = false;
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i].permission === value || permissions[i].permission === "*:*:*") {
      b = true;
      break;
    }
  }
  return b;
}

// 注册一个全局自定义指令 `v-permission`，用于绑定按钮权限，如果无权限就移除该按钮。
Vue.directive("permission", {
  // 钩子函数，被绑定元素插入(inserted)父节点时调用 (父节点存在即可调用，不必存在于 document 中)。
  inserted: function (el, binding) {
    const { value } = binding;
    let b = false;
    if (isBlank(value)) {
      b = true;
    } else {
      if (isBoolean(value)) {
        // 布尔值
        b = value;
      } else if (isString(value)) {
        // 字符
        b = getCurRoutePerm(value);
      } else if (isObject(value)) {
        // 对象
        if (value.hasOwnProperty("match") && value.hasOwnProperty("andMatch")) {
          if (isString(value.match) && isBoolean(value.andMatch)) {
            b = getCurRoutePerm(value.match) && value.andMatch;
          }
        } else if (value.hasOwnProperty("match") && value.hasOwnProperty("orMatch")) {
          if (isString(value.match) && isBoolean(value.andMatch)) {
            b = getCurRoutePerm(value.match) || value.andMatch;
          }
        }
      }
    }
    !b && el.parentNode && el.parentNode.removeChild(el);
  }
})
