<template>
  <article class="layout-content animated fadeIn">
    <!-- 具名插槽 -->
    <header class="head" v-if="hasHeadTitle">
      <h1 class="title">{{ title }}</h1>
    </header>
    <slot></slot>
  </article>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  computed: {
    hasHeadTitle () {
      return this.title.length > 0;
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.layout-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  .head {
    margin-bottom: 24px;
    .title {
      font-size: 18px;
      line-height: 1em;
    }
  }
}
</style>
