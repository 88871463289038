export default {
  'user.login.login': '登录',
  'user.login.tab.title.one': '帐号登录',
  'user.login.tab.login.two': '原帐号登录',
  'user.login.tab.login.three': '验证码登录',
  'user.login.remember-password': '记住密码',
  'user.login.forgot-password': '忘记密码',
  'user.login.logoin-username': '请输入帐号',
  'user.login.account.placeholder': '请输入管理员帐号或手机号',
  'user.login.old-account.placeholder': '请输入原始帐号',
  'user.login.password.placeholder': '请输入密码',
  'user.login.confirm-password.placeholder': '请确认密码',
  'user.login.password.change': '修改密码',
  'user.login.logOut': '退出登录',
  'user.login.Scanqrcodeonwechat': '微信扫描二维码',
  'user.login.phone.placeholder': '请输入手机号',
  'user.login.graphic-code.placeholder': '请输入图形码',
  'user.login.veri-code.placeholder': '请输入验证码',
  'user.login.get-veri-code.btn-text': '获取验证码',
  'user.login.submit': '提交',
  'user.login.qrcode': '二维码',
  'user.login.admin': '平台管理员',
  'user.welcome.login.platform': '欢迎登录综合管理平台！',
  // error message
  'user.login.phone.error.msg': '请输入正确的手机号!',
  'user.login.lost-param.error': '缺少参数',
  'user.login.lost-vericode.error': '缺失获取验证码地址',
  'user.login.different-password.error': '两次输入的密码不一样！',
  // success message
  'user.login.set-password.message': '设置密码成功',
  // verification message
  'form.verifi.min-length-8.tip': '密码长度8-40位',
  'form.verifi.min-limit-chat.tip': '只能输入数字、大小写字母、特殊字符~!@#$%^&*_./?',
  'form.verifi.max-limit-chat.tip': '需要包含大写字母、小写字母、数字、字符这四种类型的三种或以上',
  'form.verifi.forbit-pace.tip': '禁止输入空格'
}
