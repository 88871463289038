/*!
 * @file api/upload.js
 * @desc 上传文件
 */

export default ({ request, baseURL }) => ({

  // 上传文件
  uploadFile (formData, fileName, fileUrl, timeout) {
    fileUrl = fileUrl || "/file/upload";
    return request({
      method: "post",
      // url: `${baseURL}${fileUrl}?fileName=${fileName}`,
      url: `${baseURL}${fileUrl}`,

      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: timeout || 5 * 60 * 1000
    });
  }

})
