import sysconfig from "@/config/defaultSettings"
// base 工程基础路由
export default [
  {
    path: "/login",
    name: "login",
    hidden: false,
    meta: {
      title: "登录",
      subsystemId: sysconfig.baseSubsystemId
    },
    // route level code-splitting
    // this generates a separate chunk (logon.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "logon" */ "@/views/base/login/Login")
  },
  {
    path: "/open/auth/wx",
    name: "AuthWx",
    meta: {
      title: "微信扫码登录",
      subsystemId: sysconfig.baseSubsystemId
    },
    // route level code-splitting
    // this generates a separate chunk (auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ "@/views/base/login/Auth.vue")
  },
  {
    path: "/myMenu",
    name: "myMenu",
    meta: {
      title: "我的工作台",
      topMenuId: "myMenu",
      keepAlive: true,
      subsystemId: sysconfig.baseSubsystemId
    },
    component: () => import(/* webpackChunkName: "myMenu" */ "@/components/layouts/BasicLayout.vue"),
    redirect: "/myMenu/custom",
    children: [
      {
        path: "/home",
        name: "myHome",
        meta: {
          title: "首页",
          i18nTitle: "frame.menu.homePage",
          topMenuId: "myMenu",
          keepAlive: true,
          subsystemId: sysconfig.baseSubsystemId
        },
        component: () => import(/* webpackChunkName: "myMenu" */ "@/views/Home.vue")
      },
      {
        path: "/myMenus",
        name: "myMenus",
        meta: {
          title: "常用菜单",
          i18nTitle: "frame.menus.normal",
          topMenuId: "myMenu",
          keepAlive: true,
          subsystemId: sysconfig.baseSubsystemId
        },
        component: () => import(/* webpackChunkName: "myMenu" */ "@/views/base/menu/MyMenus.vue")
      },
      {
        path: "/messages",
        name: "messageList",
        meta: {
          title: "我的消息",
          i18nTitle: "frame.my.message",
          topMenuId: "myMenu",
          keepAlive: true,
          subsystemId: sysconfig.baseSubsystemId
        },
        component: () => import(/* webpackChunkName: "message" */ "@/views/base/message/List.vue")
      }
    ]
  },
  {
    path: "/exception",
    name: "exception",
    meta: { title: "异常页" },
    component: () => import(/* webpackChunkName: "exception" */ "@/components/layouts/BasicLayout.vue"),
    redirect: "/404",
    children: [
      {
        path: "/403",
        name: "exception403",
        meta: {
          title: "403",
          subsystemId: sysconfig.baseSubsystemId
        },
        component: () => import(/* webpackChunkName: "exception" */ "@/views/base/exception/403")
      },
      {
        path: "/404",
        name: "exception404",
        meta: {
          title: "404",
          subsystemId: sysconfig.baseSubsystemId
        },
        component: () => import(/* webpackChunkName: "exception" */ "@/views/base/exception/404")
      },
      {
        path: "/500",
        name: "exception500",
        meta: {
          title: "500",
          subsystemId: sysconfig.baseSubsystemId
        },
        component: () => import(/* webpackChunkName: "exception" */ "@/views/base/exception/500")
      },
      {
        path: "/999",
        name: "exception999",
        meta: {
          title: "999",
          subsystemId: sysconfig.baseSubsystemId
        },
        component: () => import(/* webpackChunkName: "exception" */ "@/views/base/exception/999")
      }
    ]
  }
]
