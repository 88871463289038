import { getPoint } from "@/utils";
export const addEditDetailScroll = {
  data () {
    return {
      fdOffsetTop: 0, // 内嵌滚动form或detail距离顶部位置
      bsViewHeight: 0, // 浏览器可视区域高度
      // gfOffsetHeight: 0, // 通用底部高度
      fdContentHeight: 0, // 内嵌滚动form或detail内容区域高度 tabPosition = top，即当tab在头部时使用这个
      fdContentHeight2: 0 // 内嵌滚动form或detail内容区域高度 tabPosition = left，即当tab在左边时使用这个
    }
  },

  mounted () {
    this.$nextTick(() => {
      // 浏览器可视区域高度
      this.bsViewHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // 是否需要内联滚动的table
      this.fdOffsetTop = this.$refs["fdInnerScoll"] ? getPoint(this.$refs["fdInnerScoll"]).t : 0; // this.$refs["fdInnerScoll"].offsetTop;

      // globalFooter 是页面底部
      // this.gfOffsetHeight = document.getElementById("globalFooter").offsetHeight;

      // tabPosition = top，即当tab在头部时使用这个
      this.fdContentHeight = this.bsViewHeight - this.fdOffsetTop - 56;

      // tabPosition = let，即当tab在左边时使用这个
      this.fdContentHeight2 = this.bsViewHeight - this.fdOffsetTop - 6;
    })
  }
}
