import store from "@/store";
import router from "@/router";
import message from "@/components/resetAntdv/Message";
import i18n from "@/locales";

function logout (err) {
  message.error(err.response.data.msg);
  store.dispatch("Logout").then(() => {
    let veriFlag = localStorage.getItem("PAUSE_VERI");
    if (!veriFlag) {
      router.push("/login");
    }
  });
}

/**
 * 请求失败后的错误统一处理
 * @param {Object} err 请求失败的状态
 */
export default (err) => {
  // 状态码判断
  if (err && err.response) {
    if (err.response.data && err.response.data.msg) {
      if (err.response.status === 401) {
        message.error(err.response.data.msg, 2, () => {
          store.dispatch("Logout").then(() => {
            let veriFlag = localStorage.getItem("PAUSE_VERI");
            if (!veriFlag) {
              router.push("/login");
            }
          });
        });
      } else {
        // message.error(err.response.data.msg);
        message.error("发生错误，请联系管理员！");
      }
    } else {
      switch (err.response.status) {
        case 400:
          message.error(i18n.t("frame.http.status.code.400"));
          break;
        case 401:
          if (err.response.data.code === "4001") {
            message.error(i18n.t("frame.http.status.code.401-1"));
          } else {
            message.error(i18n.t("frame.http.status.code.401-2"));
          }
          store.dispatch("Logout").then(() => {
            let veriFlag = localStorage.getItem("PAUSE_VERI");
            if (!veriFlag) {
              router.push("/login");
            }
          });
          break;
        case 403:
          message.error(i18n.t("frame.http.status.code.403"));
          break;
        case 404:
          message.error(i18n.t("frame.http.status.code.404"));
          break;
        case 405:
          message.error(i18n.t("frame.http.status.code.405"));
          break;
        case 408:
          message.error(i18n.t("frame.http.status.code.408"));
          break;
        case 500:
          message.error(i18n.t("frame.http.status.code.500"));
          break;
        case 501:
          message.error(i18n.t("frame.http.status.code.501"));
          break;
        case 502:
          message.error(i18n.t("frame.http.status.code.502"));
          break;
        case 503:
          message.error(i18n.t("frame.http.status.code.503"));
          break;
        case 504:
          message.error(i18n.t("frame.http.status.code.504"));
          break;
        case 505:
          message.error(i18n.t("frame.http.status.code.505"));
          break;
        case 11071:
          logout(err);
          break;
        case 11072:
          logout(err);
          break;
        case 11073:
          logout(err);
          break;
        case 11074:
          logout(err);
          break;
        case 11075:
          logout(err);
          break;
        default:
          message.error(
            `${i18n.t("frame.http.status.code.default")}${err.response.status}`
          );
      }
    }
  } else {
    let errorMsg = err.data?.msg || undefined;

    if (errorMsg) {
      // message.error(errorMsg);
      message.error("发生错误，请联系管理员！");
    } else {
      console.log(errorMsg);
    }
    if (err.data.code === 401) {
      store.dispatch("Logout").then(() => {
        let veriFlag = localStorage.getItem("PAUSE_VERI");
        if (!veriFlag) {
          router.push("/login");
        }
      });
    }
  }
};
