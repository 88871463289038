export default {
  'web.site.name': "综合管理平台",
  'web.site.slogan.one': "餐饮数智化的引领者",
  'web.site.slogan.two': "",
  'web.site.home': "首页",
  submit: '提交',
  save: '保存',
  'submit.ok': '提交成功',
  'save.ok': '保存成功',
  'back.forgot': '返回',
  query: '查询',
  'recent-query': '最近查询',
  reset: '重置',
  'return-to-menu-management': '返回菜单管理',
  'back-to-previous-page': '返回上一页',
  'expand-filter-criteria': '展开筛选条件',
  'collapse-filter-criteria': '展开筛选条件',
  'please-enter': '请输入',
  'please-select': '请选择',
  more: '更多',
  compact: '紧凑',
  secondary: '中等',
  easy: '宽松',
  'sequence-number': '序号',
  close: '关闭',
  preview: '预览',
  cancel: '取消',
  ok: '确定',
  'Determine-the-reset-form': '确定重置表单',
  'OK-to-close-this-page': '确定关闭此页面',
  bulk: '批量',
  sort: '排序',
  'Add-item': '加项',
  'Delete-item': '减项',
  'Customized-form-entries': '自定义表单项',
  'General-form': '通用表单',
  'Please-select-operation-data': '请选择操作数据',
  'Toggle-List': '切换列表',
  'Click-to-download-the-attachment': '点击下载附件',
  'Your-browser-does-not-support-video-tags': '您的浏览器不支持 video 标签',
  'Your-browser-does-not-support-audio-elements': '您的浏览器不支持 video 元素',
  item: '项',
  'No-data-available': '暂无数据',
  'Please-enter-search-content': '请输入搜索内容'
}
